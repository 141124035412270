const patient_list_ptBR = {
  title: 'Pacientes',
  search_placeholder: 'Busque por nome',
  columns: {
    name: 'Nome',
    document: 'CPF'
  },
  select_invite_modal: {
    title: 'Convidar paciente',
    description:
      'Convide os pacientes individualmente ou em lote. Os pacientes convidados irão receber um e-mail para acessar o aplicativo da L2D e poderão agendar e participar de teleconsultas.'
  },

  invite_modal_in_batch: {
    title: 'Convidar paciente em lote',
    description:
      'Baixe a planilha modelo e preencha o CPF (ou CNS), nome completo e e-mail dos pacientes que serão convidados. Eles receberão um e-mail com detalhes para baixar o aplicativo L2D.',

    processing_data: 'Os dados estão sendo processados... ',
    wait_a_moment: 'Aguarde um momento',

    response: {
      title: 'Os dados foram processados com sucesso!',
      description: 'Confira abaixo o status do processamento',

      user: '{{count}} usuário',
      user_plural: '{{count}} usuários',
      errors:
        ' com dados inválidos não foram convidados/cadastrados. Baixe o relatório de erros para conferir as inconsistências.',
      success:
        ' foram cadastrados com sucesso e receberam o convite para acesso ao aplicativo via e-mail.',
      info: 'foram cadastrados mas não receberam convite para acesso ao aplicativo pois o campo de e-mail não foi preenchido. Baixe o relatório para conferir.',
      download: 'Baixar relatório',
      tabs: {
        invalid: 'Dados inválidos',
        registered_and_invited: 'Cadastrados e convidados',
        registered: 'Cadastrados'
      }
    },

    upload_plan: 'Envie a planilha',
    send_plan_description: 'Arraste e solte o arquivo aqui ou',
    units: {
      label: 'Unidades',
      description:
        'Adicione as unidades que o paciente terá acesso para solicitar consultas pelo aplicativo',
      no_unit: 'Por favor, adicione pelo menos uma unidade.'
    }
  },
  invite_modal_individual: {
    title: 'Convide por e-mail um paciente',
    description: 'Convide um paciente para ter acesso à teleconsulta no aplicativo L2D Digital',

    warn: 'Você precisa preencher no mínimo um dos campos acima.',
    fields: {
      cpf: 'CPF',
      cns: 'CNS',
      name: 'Nome completo',
      email: 'E-email',
      units: {
        label: 'Unidades',
        description:
          'Adicione as unidades que o paciente terá acesso para solicitar consultas pelo aplicativo',
        no_unit: 'Por favor, adicione pelo menos uma unidade.'
      }
    }
  },
  actions: {
    new: 'Novo paciente',
    invite: 'Convidar pacientes',

    add_unit: 'Adicionar unidade',

    in_batch: 'Em lote',
    individual: 'Individual',

    send_invite: 'Enviar convite',
    select: 'Selecionar',

    download_plan: 'Baixar planilha',
    select_file: 'Selecionar arquivo',

    proccess_data: 'Processar dados'
  }
}

export default patient_list_ptBR
