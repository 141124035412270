import { useEffect, useState } from 'react'
import { CARE_TYPE, Consultation, PaginationParams, CONSULTATION_STATUS } from '../../types'
import Dropdown, { DropdownOption } from '../../components/dropdown'
import { Column, SortedColumn } from '../../components/table/types'
import { useListConsultations } from '../../hooks/useConsultation'
import ProtectedComponent from '../../components/protected'
import { useNavigate, useParams } from 'react-router-dom'
import DatePicker from '../../components/datepicker'
import PermissionEnum from '../../types/permissions'
import SearchField from '../../components/search'
import { useTranslation } from 'react-i18next'
import { formatInTimeZone } from 'date-fns-tz'
import Content from '../../components/content'
import { Plus } from '@phosphor-icons/react'
import Button from '../../components/button'
import Table from '../../components/table'
import Tag from '../../components/tag'
import { parse } from 'date-fns'

const pageSize = 10

const ConsultationPage: React.FC = () => {
  const navigate = useNavigate()
  const [totalPages, setTotalPages] = useState(0)
  const { t } = useTranslation('consultation_list')
  const { care_type } = useParams<{ care_type: CARE_TYPE }>()
  const [sortedColumn, setSortedColumn] = useState<SortedColumn>()

  const [params, setParams] = useState<PaginationParams & { status?: string; date?: string }>({
    filter: '',
    page: 0,
    pageSize
  })

  const { data: consultations, isFetching } = useListConsultations({
    ...params,
    sortedColumn,
    care_type
  })

  const STATUS_OPTIONS: DropdownOption[] = [
    {
      label: t(`columns.status_type.${CONSULTATION_STATUS.FINISHED}`),
      value: CONSULTATION_STATUS.FINISHED
    },
    {
      label: t(`columns.status_type.${CONSULTATION_STATUS.IN_ROOM}`),
      value: CONSULTATION_STATUS.IN_ROOM
    },
    {
      label: t(`columns.status_type.${CONSULTATION_STATUS.SCHEDULED}`),
      value: CONSULTATION_STATUS.SCHEDULED
    },
    {
      label: t(`columns.status_type.${CONSULTATION_STATUS.WAITING}`),
      value: CONSULTATION_STATUS.WAITING
    }
  ]

  useEffect(() => {
    if (consultations?.totalItems) {
      setTotalPages(Math.ceil(consultations.totalItems / pageSize))
    }
  }, [consultations])

  const handlePage = (newPage: number) => {
    setParams(oldState => ({ ...oldState, page: newPage }))
  }

  const getColorByStatus = (status: CONSULTATION_STATUS) => {
    switch (status) {
      case CONSULTATION_STATUS.WAITING:
        return 'text-highlightOrange-pure'
      case CONSULTATION_STATUS.FINISHED:
        return 'text-highlightGreen-pure'
      default:
        return 'text-primaryBrand-primary'
    }
  }

  const columns = [
    {
      header: t('columns.code'),
      accessor: 'code',
      className: 'sm:max-w-24',
      order_key: 'consultation.code'
    },
    {
      header: t('columns.date'),
      accessor: 'date',
      className: 'sm:max-w-40',
      order_key: 'order_date'
    },
    {
      header: t('columns.status'),
      accessor: 'status',
      className: 'sm:max-w-40',
      order_key: 'status_order'
    },
    { header: t('columns.patient'), accessor: 'patient', order_key: 'patient.name' },
    {
      header: t('columns.specialty'),
      accessor: 'specialty',
      order_key: 'specialty.name'
    },
    {
      header: t('columns.requester'),
      accessor: 'requester',
      order_key: 'requester.name'
    },
    {
      header: t('columns.specialist'),
      accessor: 'specialist',
      order_key: 'specialist.name'
    },
    {
      header: t('columns.created_by'),
      accessor: 'created_by',
      order_key: 'created_by.name'
    },
    { header: t('columns.unit'), accessor: 'unit', order_key: 'unit.name' }
  ] as Column[]

  const onNavigate = (row: { id?: string }) => {
    navigate(row.id!)
  }

  const tableData =
    consultations?.data?.map((consultation: Consultation) => ({
      id: consultation.id,
      rawDate: new Date(consultation.start_date || consultation.created_at!),
      date: consultation.start_date
        ? formatInTimeZone(new Date(consultation.start_date), 'UTC', 'dd/MM/yyyy HH:mm')
        : formatInTimeZone(
            new Date(consultation.created_at!),
            'America/Sao_Paulo',
            'dd/MM/yyyy HH:mm'
          ),
      code: <Tag variant='secondary' color='neutral' label={consultation.code || ''} size='sm' />,
      patient: consultation.patient?.name || consultation.patient_name_temp || '-',
      specialty: consultation.specialty?.name,
      created_by: consultation.created_by?.name,
      specialist: consultation?.temp_specialist?.name || consultation?.specialist?.name,
      requester: consultation.requester?.name,
      status: (
        <span className={getColorByStatus(consultation.status!)}>
          ● {t(`columns.status_type.${consultation.status}`)}
        </span>
      ),
      unit: consultation.unit?.name,
      care_type: consultation.care_type
    })) || []

  const handleStatusFilter = (option: DropdownOption | null) => {
    setParams(old => ({ ...old, status: option?.value, page: 0 }))
  }

  const handleDateFilter = (date?: Date | null) => {
    setParams(old => ({
      ...old,
      date: date ? formatInTimeZone(date, 'America/Sao_Paulo', 'yyyy-MM-dd') : undefined,
      page: 0
    }))
  }

  return (
    <Content
      subtitle={t('subtitle')}
      title={t(`title_${care_type}`)}
      rightContent={
        <div className='flex gap-4'>
          <ProtectedComponent
            permissions={[
              PermissionEnum.service_create_to_other_users,
              PermissionEnum.service_requester
            ]}
          >
            <Button
              size='sm'
              iconLeft={<Plus size={16} />}
              label={t('actions.new_consultation')}
              onClick={() => navigate('new')}
            />
          </ProtectedComponent>
        </div>
      }
    >
      <div className={`flex flex-col`}>
        <div className='mb-2 grid grid-cols-4 items-center gap-2'>
          <div className='col-span-2'>
            <SearchField
              value={params.filter || ''}
              placeholder='Busque por um paciente ou código de atendimento'
              onChange={event => setParams(old => ({ ...old, filter: event.target.value }))}
            />
          </div>
          <DatePicker
            name='date'
            onChange={handleDateFilter}
            value={params.date ? parse(params.date, 'yyyy-MM-dd', new Date()) : null}
          />
          <Dropdown
            className=''
            name='status'
            options={STATUS_OPTIONS}
            value={params.status || ''}
            placeholder='Filtro por status'
            onChangeValue={handleStatusFilter}
          />
        </div>

        <Table
          data={tableData}
          columns={columns}
          isLoading={isFetching}
          totalPages={totalPages}
          currentPage={params.page}
          onPageChange={handlePage}
          sortedColumn={sortedColumn}
          handleSort={setSortedColumn}
          onClickRow={row => onNavigate(row)}
          className='max-h-[calc(100dvh-300px)] md:max-h-[calc(100dvh-320px)]'
        />
      </div>
    </Content>
  )
}

export default ConsultationPage
