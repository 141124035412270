/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Yup from 'yup'
import { useFormik } from 'formik'
import PatientService from '../../services/patient.service'
import { useToastContext } from '../../contexts/toast'
import { useState } from 'react'

interface InviteBatchResponse {
  invalid: number
  registered_and_invited: number
  invited: number
}
interface IndividualFormValues {
  email: string
  name: string
  cpf: string
  units: string[]
  cns_number: string
}

const transformData = (data: IndividualFormValues) => {
  return {
    email: data.email,
    name: data.name,
    cpf: data.cpf,
    units: data.units,
    cns_number: data.cns_number
  }
}

export const useIndividualInviteFormik = (onSuccess?: () => void) => {
  const { toast } = useToastContext()
  const validationSchema = Yup.object()
    .shape({
      cpf: Yup.string(),
      cns_number: Yup.string(),
      email: Yup.string().email('Insira um e-mail válido.').required('E-mail é obrigatório'),
      name: Yup.string().required('Por favor, insira o nome completo do paciente.'),
      units: Yup.array().of(Yup.string()).min(1, 'Por favor, adicione pelo menos uma unidade.'),
      clients: Yup.array().min(1, 'Por favor, adicione pelo menos uma unidade.')
    })
    .test('cpf-or-cns', 'Por favor, insira o CNS ou CPF do paciente', values => {
      if (!values) return false
      return Boolean(values.cpf?.trim() || values.cns_number?.trim())
    })

  const initialValues = {
    cpf: '',
    cns_number: '',
    email: '',
    name: '',
    units: [],
    clients: []
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async values => {
      try {
        await PatientService.sendIndividualInvite(transformData(values))
        toast.success('Convite enviado com sucesso')
        onSuccess?.()
      } catch (error) {
        toast.error('Erro ao enviar convite')
      }
    }
  })

  return formik
}

export const useBatchInviteFormik = () => {
  const [loading, setLoading] = useState(false)

  const [response, setResponse] = useState<InviteBatchResponse | null>(null)

  const { toast } = useToastContext()
  const validationSchema = Yup.object().shape({
    units: Yup.array().of(Yup.string()).min(1, 'Por favor, adicione pelo menos uma unidade.'),
    clients: Yup.array(),
    patients: Yup.array().min(1, 'Por favor, adicione pelo menos um paciente.')
  })

  const initialValues = {
    units: [],
    clients: [],
    patients: []
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async values => {
      console.log('values', values)
      try {
        setLoading(true)
        const response = await PatientService.sendBatchInvite({
          units: values.units,
          patients: values.patients
        })
        setResponse(response.data)
      } catch (error) {
        toast.error('Erro ao enviar convite')
      } finally {
        setTimeout(() => {
          setLoading(false)
        }, 3000)
      }
    }
  })

  return {
    formik,
    loading,
    response
  }
}
