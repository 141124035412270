/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import CidsSelect from '../../../../../components/dropdown/cids-dropdown'
import { useGetConsultation } from '../../../../../hooks/useConsultation'
import { useUpdateDiagnosis } from '../../../../../hooks/useCids'
import { CID } from '../../../../../types'

export const Diagnosis: React.FC<{
  consultationId: string
  specialist_id?: string
}> = ({ consultationId }) => {
  const { data: consultation } = useGetConsultation(consultationId)
  const updateDiagnosis = useUpdateDiagnosis(consultationId)

  const [initialDiagnosis, setInitialDiagnosis] = useState<CID | undefined>()
  const [finalDiagnosis, setFinalDiagnosis] = useState<CID | undefined>()

  // Atualiza os estados locais apenas se os valores da API forem diferentes dos locais
  useEffect(() => {
    if (consultation) {
      if (
        consultation.initial_diagnosis?.id !== initialDiagnosis?.id ||
        consultation.final_diagnosis?.id !== finalDiagnosis?.id
      ) {
        setInitialDiagnosis(consultation.initial_diagnosis as unknown as CID)
        setFinalDiagnosis(consultation.final_diagnosis as unknown as CID)
      }
    }
  }, [consultation])

  useEffect(() => {
    if (initialDiagnosis?.id) {
      updateDiagnosis.mutate({
        initial_diagnosis_id: initialDiagnosis.id,
        final_diagnosis_id: finalDiagnosis?.id || ''
      })
    }
  }, [initialDiagnosis])

  useEffect(() => {
    if (finalDiagnosis?.id) {
      updateDiagnosis.mutate({
        initial_diagnosis_id: initialDiagnosis?.id || '',
        final_diagnosis_id: finalDiagnosis.id
      })
    }
  }, [finalDiagnosis])

  return (
    <div className='flex h-[calc(100dvh-350px)] w-full flex-wrap gap-4'>
      <CidsSelect
        name='initial_diagnosis'
        label='Hipótese Diagnóstica Inicial (CID10)'
        placeholder='Procure ou selecione um item'
        onBlur={() => {}}
        onChange={setInitialDiagnosis}
        value={initialDiagnosis?.id || ''}
      />

      <CidsSelect
        name='final_diagnosis'
        label='Diagnóstico Final (CID10)'
        placeholder='Procure ou selecione um item'
        onBlur={() => {}}
        onChange={setFinalDiagnosis}
        value={finalDiagnosis?.id || ''}
      />
    </div>
  )
}
