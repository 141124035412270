import React, { useCallback, useEffect, useState } from 'react'
import ConsultationService from '../../../../../services/consultation.service'
import { DocumentByQrCodeModal } from './modals/DocumentByQrCodeModal'
import { ConsultationUploadFile, File } from '../../../../../types'
import { ChooseDocumentType } from './modals/ChooseDocumentType'
import { useToastContext } from '../../../../../contexts/toast'
import FileService from '../../../../../services/file.service'
import BlankCard from '../../../../../components/blank-card'
import Button from '../../../../../components/button'
import { Paperclip } from '@phosphor-icons/react'
import { ViewFileModal } from './ViewFileModal'
import { useTranslation } from 'react-i18next'
import { Loading } from 'semente-js'
import { FileTag } from './FileTag'

export const Interactions: React.FC<{
  consultation_id: string
}> = ({ consultation_id }) => {
  const { t } = useTranslation('consultation_details')
  const { toast } = useToastContext()
  const [isOpenDocumentModal, setIsOpenDocumentModal] = useState(false)
  const [uploadFile, setUploadFile] = useState<ConsultationUploadFile>()
  const [isLoading, setIsLoading] = useState(true)
  const [files, setFiles] = useState<File[]>([])
  const [openDocument, setOpenDocument] = useState<File>()
  const [hasLoadedFilesOnce, setHasLoadedFilesOnce] = useState(false)

  const loadFiles = useCallback(async () => {
    setIsLoading(true)
    try {
      const files = await FileService.getConsultationFile(consultation_id)
      setFiles(files)
    } catch (_) {
      toast.error('Erro ao carregar arquivos da consulta')
    }
    setIsLoading(false)
  }, [consultation_id, toast])

  useEffect(() => {
    if (hasLoadedFilesOnce) return () => {}
    setHasLoadedFilesOnce(true)
    loadFiles().then(r => r)
  }, [loadFiles, hasLoadedFilesOnce])

  const createFileUpload = async () => {
    const consultationUploadFile =
      await ConsultationService.generateConsultationUploadFile(consultation_id)
    setUploadFile(consultationUploadFile)
    setIsOpenDocumentModal(false)
  }

  return (
    <div className='flex flex-col gap-4'>
      <BlankCard>
        <div className='flex items-center gap-6'>
          <span className='text-label-md font-bold'>{t('documents')}</span>
          <Button
            size='sm'
            onClick={() => setIsOpenDocumentModal(true)}
            variant='secondary'
            label={t('actions.attach_document')}
            iconLeft={<Paperclip size={16} />}
          />
        </div>
        <div className='mt-4 flex flex-wrap'>
          {isLoading && files.length === 0 && (
            <div className='flex w-full items-center justify-center'>
              <Loading />
            </div>
          )}
          {!isLoading &&
            files.length > 0 &&
            files.map(document => (
              <div key={document.key} className='p-1'>
                <FileTag
                  document={document}
                  onOpenFile={() => {
                    setOpenDocument(document)
                  }}
                />
              </div>
            ))}

          {!!openDocument && (
            <ViewFileModal
              onClose={() => setOpenDocument(undefined)}
              document={openDocument}
              onNextFile={() => {
                const index = files.findIndex(file => file.id === openDocument.id)
                const nextIndex = index + 1
                const nextDocument = files[nextIndex >= files.length ? 0 : nextIndex]
                setOpenDocument(nextDocument)
              }}
              onPreviousFile={() => {
                const index = files.findIndex(file => file.id === openDocument.id)
                const previousIndex = index - 1
                const previousDocument = files[previousIndex < 0 ? files.length - 1 : previousIndex]
                setOpenDocument(previousDocument)
              }}
            />
          )}
        </div>
      </BlankCard>

      <ChooseDocumentType
        isOpen={isOpenDocumentModal}
        consultationId={consultation_id}
        handleQRCodeButton={createFileUpload}
        closeModal={() => setIsOpenDocumentModal(false)}
      />

      <DocumentByQrCodeModal
        consultationUploadFile={uploadFile}
        closeModal={() => setUploadFile(undefined)}
      />
    </div>
  )
}
