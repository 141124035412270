/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from '../../../components/modal'
import BlankCard from '../../../components/blank-card'
import { User, UsersFour } from '@phosphor-icons/react'
import { INVITE_TYPE } from '../../../types'
import { InviteModalInBatch } from './InviteModalInBatch'
import { InviteModalIndividual } from './InviteModalIndividual'
import { useBatchInviteFormik } from '../../../hooks/forms/useInvite'
import { ResponseModalInBatch } from './ResponseModalInBatch'

interface Props {
  open: boolean
  onClose: () => void
}

export const SelectInviteTypeModal: React.FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation('patient_list')
  const {
    formik: formikBatchInvite,
    loading: loadingBatchInvite,
    response
  } = useBatchInviteFormik()
  const [openModalResponse, setOpenModalResponse] = useState(false)

  const [inviteModal, setModalInvite] = useState<{
    isOpen: boolean
    type: INVITE_TYPE | null | string
  }>({
    isOpen: false,
    type: null
  })

  useEffect(() => {
    if (response) {
      setOpenModalResponse(true)
      setModalInvite({ isOpen: false, type: null })
    }
  }, [response])

  return (
    <Modal
      title={<span className='text-heading-xs'>{t(`select_invite_modal.title`)}</span>}
      isOpen={open}
      onClose={onClose}
    >
      <div className='h-[200px] w-[480px] max-w-[90vw]'>
        <p className='text-body-md text-neutralContent-quartenary'>
          {t(`select_invite_modal.description`)}
        </p>

        <div className='mt-6 flex h-[100px] w-full gap-2 '>
          <BlankCard
            onClick={() => setModalInvite({ isOpen: true, type: 'individual' })}
            className='w-full cursor-pointer hover:border hover:border-neutralBorder-brand'
          >
            <div className='flex flex-col items-center  justify-center'>
              <div className='rounded-500 bg-neutralBackground-secondary p-2'>
                <User size={24} />
              </div>
              <span>{t('actions.individual')}</span>
            </div>
          </BlankCard>
          <BlankCard
            onClick={() => setModalInvite({ isOpen: true, type: 'batch' })}
            className='w-full cursor-pointer  hover:border hover:border-neutralBorder-brand '
          >
            <div className='flex flex-col items-center justify-center'>
              <div className='rounded-500 bg-neutralBackground-secondary p-2'>
                <UsersFour size={24} />
              </div>
              <span>{t('actions.in_batch')}</span>
            </div>
          </BlankCard>
        </div>
      </div>

      <InviteModalIndividual
        onSuccess={onClose}
        onClose={() => setModalInvite({ isOpen: false, type: null })}
        open={inviteModal.isOpen && inviteModal.type === 'individual'}
      />

      <InviteModalInBatch
        //@ts-expect-error: ver depois como resolver a tipagem
        formik={formikBatchInvite}
        loading={loadingBatchInvite}
        onClose={() => setModalInvite({ isOpen: false, type: null })}
        open={inviteModal.isOpen && inviteModal.type === 'batch'}
        setModalInvite={setModalInvite}
      />
      <ResponseModalInBatch
        open={openModalResponse && !!response}
        onClose={() => {
          setOpenModalResponse(false)
          onClose()
        }}
        response={response}
      />
    </Modal>
  )
}
