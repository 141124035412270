import React, { useEffect, useRef } from 'react'

type ButtonAction = {
  label: string
  onClick: () => void
  icon?: React.ReactNode
  className?: string
}

type Props = {
  actions: ButtonAction[]
  isOpen: boolean
  toggleDropdown: () => void
  className?: string
}

const FloatingDropdown: React.FC<Props> = ({ actions, isOpen, toggleDropdown, className }) => {
  const dropdownRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        toggleDropdown()
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen, toggleDropdown])

  return (
    <div className='relative z-50 inline-block text-left' ref={dropdownRef}>
      {isOpen && (
        <div
          className={`absolute right-0 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 ${className} `}
        >
          <div className='py-1'>
            {actions.map((action, index) => (
              <button
                key={index}
                onClick={() => {
                  action.onClick()
                  toggleDropdown()
                }}
                className={`group flex w-full items-center px-4 py-2 text-sm hover:bg-highlightBlue-light ${action.className}`}
              >
                {action.icon && <span className='mr-3'>{action.icon}</span>}
                {action.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default FloatingDropdown
