import { format } from 'date-fns'
import Button from '../button'
import Modal from '../modal'
import { toZonedTime } from 'date-fns-tz'

export type TclePerson = { name?: string; birthdate?: string; document?: string; cpf?: string }

type Props = {
  isOpen: boolean
  rejectTcle: VoidFunction
  acceptTcle: VoidFunction
  isLoading?: boolean
  person?: TclePerson
}

export const TcleModal: React.FC<Props> = ({
  isOpen,
  rejectTcle,
  acceptTcle,
  isLoading,
  person
}) => {
  return (
    <Modal isOpen={isOpen}>
      <div className='mx-4 flex max-h-[90vh] flex-col gap-4 overflow-auto'>
        <div className='text-heading-sm'>{'Termo de Consentimento Livre e Esclarecido – TCLE'}</div>
        <p>
          {'Nome do usuário: '}
          <span>{person?.name || '-'}</span>
        </p>
        <p>
          {'Data de Nascimento: '}
          <span>{format(toZonedTime(person?.birthdate || new Date(), 'UTC'), 'dd/MM/yyyy')}</span>
        </p>
        <p>
          {'Eu,  '}
          <span>{person?.name || '-'}</span>
          {', portador(a) do CPF nº '}
          <span>{person?.document || person?.cpf || '-'}</span>
          {
            ', informo que me foram esclarecidas as características da Teleconsulta, as minhas responsabilidades e as do profissional de saúde que me atenderá, e que houve minha concordância para realização do atendimento.'
          }
        </p>
        <p>{'Na ocasião, recebi as seguintes informações:'}</p>
        <ul className='mx-4 list-disc'>
          <li>
            {
              'A teleconsulta é o atendimento à distância realizado por profissional de saúde de nível superior;'
            }
          </li>
          <li>{'A teleconsulta e a teleinterconsulta são admitidas como práticas éticas;'}</li>
          <li>
            {
              'A plataforma assegura a proteção e o sigilo das informações contra vazamento de dados, segundo as normas da Lei Geral de Proteção de Dados (LGPD);'
            }
          </li>
          <li>
            {
              'Pode haver gravação durante o atendimento para fins exclusivos de registro no prontuário;'
            }
          </li>
          <li>
            {
              'Pelo sigilo do atendimento, não poderei fazer qualquer gravação sem autorização explícita do profissional que prestou atendimento;'
            }
          </li>
          <li>
            {'Caso o profissional considere necessário, poderá solicitar atendimento presencial;'}
          </li>
        </ul>
        <p>
          {
            'Confirmo que li, compreendi e concordo com todos os termos deste Termo de Ciência e Consentimento Livre e Esclarecido para Teleconsulta.'
          }
        </p>
        <div className='flex w-full justify-end gap-4'>
          <Button
            variant='secondary'
            onClick={rejectTcle}
            label='Recusar e Voltar'
            isLoading={isLoading}
          />
          <Button label='Aceitar e continuar' onClick={acceptTcle} isLoading={isLoading} />
        </div>
      </div>
    </Modal>
  )
}
