import {
  Check,
  VideoCamera,
  PaperPlaneTilt,
  ArrowSquareOut,
  ChatDots,
  Download
} from '@phosphor-icons/react'
import { CARE_TYPE, Consultation, CONSULTATION_STATUS, CONSULTATION_TYPE } from '../../../../types'
import { ConfirmationStartCallModal } from '../DialogsConfirmationCall'
import { formatBirthDate } from '../../../../helpers/formatBirthDate'
import UnitPermissionsEnum from '../../../../types/unit-permissions'
import ProtectedComponent from '../../../../components/protected'
import InviteProfessionalModal from './InviteProfessionalModal'
import { calculateAge } from '../../../../helpers/formatAge'
import PermissionEnum from '../../../../types/permissions'
import BlankCard from '../../../../components/blank-card'
import InvitePatientModal from './InvitePatientModal'
import { useAuth } from '../../../../contexts/auth'
import Avatar from '../../../../components/avatar'
import Button from '../../../../components/button'
import { ROUTES } from '../../../../routes/path'
import { useTranslation } from 'react-i18next'
import Tag from '../../../../components/tag'
import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { isToday } from 'date-fns'
import PatientService from '../../../../services/patient.service'

type Props = {
  onStart: () => void
  isStarted?: boolean
  consultation: Consultation
}

const PatientDetails: React.FC<Props> = ({ onStart, consultation, isStarted }) => {
  const { t } = useTranslation('consultation_details')
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [inviteProfessional, setInviteProfessional] = useState(false)
  const [invitePatient, setInvitePatient] = useState(false)
  const [loading, setLoading] = useState(false)
  const { user } = useAuth()

  const showButtonToStartChat = useMemo(() => {
    if (
      consultation.care_type === CARE_TYPE.CHAT &&
      consultation.status === CONSULTATION_STATUS.WAITING
    ) {
      return true
    }

    return false
  }, [consultation])

  const showButtonToEnterChat = useMemo(() => {
    if (isStarted) return false

    if (
      consultation.care_type === CARE_TYPE.CHAT &&
      consultation.status === CONSULTATION_STATUS.IN_ROOM
    ) {
      if (consultation.specialist_id === user?.id || !consultation.specialist_id) return true

      if (
        consultation.type === CONSULTATION_TYPE.TELEINTERCONSULTATION &&
        user?.id === consultation.requester_id
      ) {
        return true
      }
    }

    return false
  }, [user, consultation, isStarted])

  const showButtonToStartVideo = useMemo(() => {
    if (
      consultation.care_type === CARE_TYPE.ELECTIVE_CARE &&
      consultation.status === CONSULTATION_STATUS.SCHEDULED &&
      isToday(consultation.start_date!)
    ) {
      if (
        consultation.type === CONSULTATION_TYPE.TELEINTERCONSULTATION &&
        user?.id === consultation.requester_id
      ) {
        return true
      }

      return (
        consultation.specialist_id === user?.id || consultation?.temp_specialist?.id === user?.id
      )
    }

    if (
      consultation.care_type === CARE_TYPE.EMERGENCY_CARE &&
      consultation.status === CONSULTATION_STATUS.WAITING
    ) {
      if (
        consultation.type === CONSULTATION_TYPE.TELEINTERCONSULTATION &&
        user?.id === consultation.requester_id
      ) {
        return true
      }

      if (!consultation.specialist_id) return true
    }

    return false
  }, [consultation, user])

  const showButtonToEnterVideo = useMemo(() => {
    if (
      isStarted ||
      consultation.status !== CONSULTATION_STATUS.IN_ROOM ||
      consultation.care_type === CARE_TYPE.CHAT
    )
      return false

    if (consultation.specialist_id === user?.id || !consultation.specialist_id) return true

    if (
      consultation.type === CONSULTATION_TYPE.TELEINTERCONSULTATION &&
      user?.id === consultation.requester_id
    ) {
      return true
    }

    return false
  }, [user, consultation, isStarted])

  const downloadHistoric = async () => {
    setLoading(true)
    try {
      const response = await PatientService.downloadHistoric(consultation.patient?.cpf || '')

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `historic.pdf`)
      document.body.appendChild(link)
      link.click()

      link.parentNode?.removeChild(link)
    } catch (error) {
      console.log(error)
    }

    setLoading(false)
  }

  return (
    <>
      <BlankCard className='flex w-full items-center justify-between p-4'>
        <div className='flex items-center gap-4'>
          {(consultation.patient || consultation.patient_name_temp) && (
            <>
              <Avatar
                user={consultation?.patient}
                name={consultation.patient_name_temp}
                size='lg'
              />

              <div>
                {consultation?.patient ? (
                  <Link
                    target='_blank'
                    rel='noopener noreferrer'
                    to={ROUTES.patient.details.replace(':id', consultation.patient?.cpf as string)}
                  >
                    <h2 className='flex cursor-pointer items-center gap-2 text-label-lg text-primaryBrand-primary'>
                      {consultation?.patient?.name} <ArrowSquareOut size={16} />
                    </h2>
                  </Link>
                ) : (
                  <h2 className='flex items-center gap-2 text-label-lg text-primaryBrand-primary'>
                    {consultation?.patient_name_temp}
                  </h2>
                )}
                <div className='mt-3'>
                  <span className='text-label-sm text-neutralContent-disabled'>
                    {consultation.patient?.sex || consultation.patient_sex_temp}
                    {' • '}
                  </span>
                  <span className='text-label-sm text-neutralContent-disabled'>
                    {!!consultation?.patient?.birthdate
                      ? formatBirthDate(consultation?.patient?.birthdate)
                      : ''}{' '}
                  </span>
                  <span className='text-label-sm text-neutralContent-disabled'>
                    {!!consultation?.patient?.birthdate
                      ? `• ${calculateAge(consultation?.patient?.birthdate)}`
                      : consultation.patient_age_temp}
                    {' anos •  '}
                  </span>
                  <span className='text-label-sm text-neutralContent-disabled'>
                    {' '}
                    {!!consultation?.patient?.height ? consultation?.patient?.height + 'cm ' : ''}
                  </span>
                  <span className='text-label-sm text-neutralContent-disabled'>
                    {!!consultation?.patient?.weight ? `• ${consultation?.patient?.weight}kg` : ''}
                  </span>
                </div>
                <div className='text-body-sm text-neutralContent-secondary'>
                  {consultation?.patient?.cpf}
                </div>
              </div>
            </>
          )}
        </div>

        <div className='flex flex-col gap-3'>
          {showButtonToStartVideo && (
            <ProtectedComponent
              permissions={[PermissionEnum.service_requester, PermissionEnum.service_specialist]}
            >
              <Button
                size='sm'
                label={t('actions.start_call')}
                iconLeft={<VideoCamera size={16} />}
                onClick={() => setOpenConfirmationModal(true)}
              />
            </ProtectedComponent>
          )}

          {showButtonToEnterVideo && (
            <ProtectedComponent
              permissions={[PermissionEnum.service_requester, PermissionEnum.service_specialist]}
            >
              <Button
                size='sm'
                onClick={onStart}
                label={t('actions.enter_call')}
                iconLeft={<VideoCamera size={16} />}
              />
            </ProtectedComponent>
          )}

          {showButtonToStartChat && (
            <ProtectedComponent
              permissions={[PermissionEnum.service_requester, PermissionEnum.service_specialist]}
            >
              <Button
                size='sm'
                label={t('actions.start_chat')}
                iconLeft={<ChatDots size={16} />}
                onClick={() => setOpenConfirmationModal(true)}
              />
            </ProtectedComponent>
          )}

          {showButtonToEnterChat && (
            <ProtectedComponent
              permissions={[PermissionEnum.service_requester, PermissionEnum.service_specialist]}
            >
              <Button
                size='sm'
                onClick={onStart}
                label={t('actions.enter_chat')}
                iconLeft={<ChatDots size={16} />}
              />
            </ProtectedComponent>
          )}

          <Button
            size='sm'
            variant='primary'
            isLoading={loading}
            iconLeft={<Download />}
            onClick={downloadHistoric}
            label={t('actions.download_historic')}
          />

          {consultation.care_type !== CARE_TYPE.CHAT &&
            consultation.unit?.permissions?.includes(UnitPermissionsEnum.invite_patient) && (
              <ProtectedComponent permissions={[PermissionEnum.service_invite]}>
                <Button
                  size='sm'
                  variant='tertiary'
                  className='border-[1px] border-primaryBrand-primary'
                  onClick={() => setInvitePatient(true)}
                  label={t('actions.invite_patient')}
                  iconLeft={<PaperPlaneTilt size={16} />}
                />
              </ProtectedComponent>
            )}

          {consultation.type === CONSULTATION_TYPE.TELEINTERCONSULTATION &&
            consultation.care_type !== CARE_TYPE.CHAT &&
            isStarted &&
            consultation.unit?.permissions?.includes(UnitPermissionsEnum.invite_professional) && (
              <ProtectedComponent
                permissions={[PermissionEnum.service_requester, PermissionEnum.service_specialist]}
              >
                <Button
                  size='sm'
                  variant='tertiary'
                  onClick={() => setInviteProfessional(true)}
                  label={t('actions.invite_professional')}
                  iconLeft={<PaperPlaneTilt size={16} />}
                />
              </ProtectedComponent>
            )}
          {consultation.status === CONSULTATION_STATUS.FINISHED && (
            <Tag
              size='lg'
              color='green'
              variant='secondary'
              leftIcon={<Check />}
              label={t('finished_consultation')}
            />
          )}
        </div>
      </BlankCard>

      <ConfirmationStartCallModal
        onContinue={onStart}
        status={consultation.status}
        open={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
      />
      {consultation.patient && (
        <InvitePatientModal
          isModalOpen={invitePatient}
          closeModal={() => setInvitePatient(false)}
          consultation={consultation}
        />
      )}

      <InviteProfessionalModal
        consultation_id={consultation?.id as string}
        isModalOpen={inviteProfessional}
        closeModal={() => setInviteProfessional(false)}
      />
    </>
  )
}

export default PatientDetails
