enum PermissionEnum {
  /** REGISTER */
  register_all_new_clients_and_units = 'register_all_new_clients_and_units',
  register_anamnesis_template = 'register_anamnesis_template',
  register_specialty = 'register_specialty',
  register_unit_type = 'register_unit_type',
  register_profile = 'register_profile',
  register_council = 'register_council',
  register_patient = 'register_patient',
  register_client = 'register_client',
  register_unit = 'register_unit',
  register_user = 'register_user',
  /** END REGISTER */

  /** LIST */
  list_all_users = 'list_all_users',
  list_clients = 'list_clients',
  list_units = 'list_units',
  list_users = 'list_users',
  list_patients = 'list_patients',
  /** END LIST */

  /** MANAGER */
  manager_create_agenda = 'manager_create_agenda',
  manager_see_other_users_agenda = 'manager_see_other_users_agenda',
  manager_transfer_agenda = 'manager_transfer_agenda',
  manager_invite_patient = 'manager_invite_patient',
  /** END MANAGER */

  /** SERVICE */
  service_create_to_other_users = 'service_create_to_other_users',
  service_access_all_services = 'service_access_all_services',
  service_do_screening = 'service_do_screening',
  service_specialist = 'service_specialist',
  service_requester = 'service_requester',
  service_follow_up = 'service_follow_up',
  service_invite = 'service_invite',
  service_delete = 'service_delete',
  /** END SERVICE */

  /** REPORTS */
  report_general = 'report_general',
  report_nps = 'report_nps',
  report_production = 'report_production',
  report_service = 'report_service',
  report_patient = 'report_patient',
  report_status = 'report_status',
  report_agenda = 'report_agenda',
  /** END REPORTS */

  /** DOCUMENT */
  document_medical_certificate = 'document_medical_certificate',
  document_prescription = 'document_prescription',
  document_medical_report = 'document_medical_report',
  document_request_referral = 'document_request_referral',
  document_request_test = 'document_request_test'
  /** END DOCUMENT */
}

export const ClientPermissions = []

export const ADMIN_PERMISSIONS = [
  PermissionEnum.list_all_users,
  PermissionEnum.register_all_new_clients_and_units,
  PermissionEnum.register_profile,
  PermissionEnum.register_anamnesis_template,
  PermissionEnum.register_unit_type,
  PermissionEnum.register_council,
  PermissionEnum.register_specialty,
  PermissionEnum.register_client,
  PermissionEnum.register_unit
]

export default PermissionEnum
