/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, FocusEvent, useMemo } from 'react'
import { Unit } from '../../types'
import Dropdown from '.'
import { useAuth } from '../../contexts/auth'
import UnitPermissionsEnum from '../../types/unit-permissions'

type Props = {
  label: string
  name: string
  placeholder?: string
  disabled?: boolean
  hint?: string
  value: string
  client_id?: string
  onChange: (unit?: Unit) => void
  unit_permission?: UnitPermissionsEnum
  onBlur: (e: FocusEvent<unknown, Element>) => void
  error?: boolean
}

const UnitSelect: React.FC<Props> = ({
  label,
  onChange,
  disabled,
  client_id,
  unit_permission,
  ...props
}) => {
  const { user, reloadCurrentUser } = useAuth()

  useEffect(() => {
    reloadCurrentUser()
  }, [reloadCurrentUser])

  const client = useMemo(() => {
    if (!client_id) return

    return user?.clients?.find(item => item.client_id === client_id)
  }, [client_id, user])

  const options = useMemo(() => {
    if (!client) return []

    return (
      (!!unit_permission
        ? client?.units?.filter(
            item => item.unit?.is_active && item.unit?.permissions?.includes(unit_permission)
          )
        : client?.units
      )?.map(item => ({ label: item?.unit?.name || '', value: item?.unit_id || '' })) || []
    )
  }, [client])

  useEffect(() => {
    if (!client_id) onChange(undefined)
  }, [client_id])

  useEffect(() => {
    if (options?.length === 1) {
      const unit = client?.units?.find(item => item.unit_id === options[0].value)?.unit
      onChange(unit)
    }
  }, [options])

  const handleSelect = (option: { value: string; label: string } | null) => {
    if (!option) {
      onChange(undefined)
    } else {
      const unit = client?.units?.find(item => item.unit_id === option.value)?.unit
      onChange(unit)
    }
  }

  return (
    <Dropdown
      label={label}
      options={options}
      onChangeValue={handleSelect}
      disabled={disabled || !client_id}
      {...props}
    />
  )
}

export default UnitSelect
