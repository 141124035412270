import PermissionEnum from '../../../types/permissions'
import { ROUTES } from '../../../routes/path'
import {
  IdentificationCard,
  AddressBookTabs,
  ClockCountdown,
  ChartPieSlice,
  CalendarDot,
  Stethoscope,
  UserCircle,
  ClockUser,
  Heartbeat,
  IconProps,
  UsersFour,
  UserGear,
  Hospital,
  Chats,
  House,
  Pulse,
  Siren
} from '@phosphor-icons/react'

export type MainMenuItem = {
  name?: string
  route?: string
  divider?: boolean
  Icon?: React.FC<IconProps>
  permission?: PermissionEnum
  permissions?: PermissionEnum[]
}

export const MAIN_MENU_ITEMS: MainMenuItem[] = [
  {
    Icon: House,
    name: 'home',
    route: ROUTES.home,
    permission: undefined
  },
  {
    Icon: CalendarDot,
    name: 'agenda',
    route: ROUTES.agenda.list,
    permission: PermissionEnum.manager_see_other_users_agenda
  },

  { divider: true, name: 'consultation' },

  {
    Icon: Siren,
    name: 'emergency_consultation',
    route: ROUTES.consultation.list_emergency,
    permissions: [
      PermissionEnum.service_specialist,
      PermissionEnum.service_requester,
      PermissionEnum.service_create_to_other_users
    ]
  },
  {
    Icon: Stethoscope,
    name: 'elective_consultation',
    route: ROUTES.consultation.list_elective,
    permissions: [
      PermissionEnum.service_specialist,
      PermissionEnum.service_requester,
      PermissionEnum.service_create_to_other_users
    ]
  },
  {
    Icon: Chats,
    name: 'chat_consultation',
    route: ROUTES.consultation.list_chat,
    permissions: [
      PermissionEnum.service_specialist,
      PermissionEnum.service_requester,
      PermissionEnum.service_create_to_other_users
    ]
  },
  {
    Icon: ClockCountdown,
    name: 'pending_consultation',
    route: ROUTES.consultation.follow_up_pending,
    permissions: [PermissionEnum.service_follow_up]
  },
  {
    Icon: ClockUser,
    name: 'in_progress_consultation',
    route: ROUTES.consultation.follow_up_in_progress,
    permissions: [PermissionEnum.service_follow_up]
  },

  { divider: true, name: 'accesses' },

  {
    Icon: AddressBookTabs,
    name: 'clients',
    route: ROUTES.client.list,
    permission: PermissionEnum.list_clients
  },
  {
    Icon: UserGear,
    name: 'users',
    route: ROUTES.user.list,
    permission: PermissionEnum.list_users
  },
  {
    Icon: Heartbeat,
    name: 'patients',
    route: ROUTES.patient.list,
    permission: PermissionEnum.list_patients
  },

  { divider: true, name: 'management' },

  {
    Icon: UserCircle,
    name: 'profile_access',
    route: ROUTES.profile.list,
    permission: PermissionEnum.register_profile
  },
  {
    Icon: Hospital,
    name: 'unit_type',
    route: ROUTES.unitType.list,
    permission: PermissionEnum.register_unit_type
  },
  {
    Icon: UsersFour,
    name: 'councils',
    route: ROUTES.council.list,
    permission: PermissionEnum.register_council
  },
  {
    Icon: IdentificationCard,
    name: 'specialties',
    route: ROUTES.specialty.list,
    permission: PermissionEnum.register_specialty
  },
  {
    Icon: Pulse,
    name: 'anamnesis_template',
    route: ROUTES.anamnesis_template.list,
    permission: PermissionEnum.register_anamnesis_template
  },
  {
    Icon: ChartPieSlice,
    name: 'dashboards',
    route: ROUTES.dashboards.list,
    permissions: [
      PermissionEnum.report_general,
      PermissionEnum.report_nps,
      PermissionEnum.report_production,
      PermissionEnum.report_service,
      PermissionEnum.report_patient,
      PermissionEnum.report_agenda,
      PermissionEnum.report_status
    ]
  }
]
