import { useCallback, useEffect, useState } from 'react'
import ConsultationService from '../services/consultation.service'
import { useToastContext } from '../contexts/toast'
import { ConsultationMessage } from '../types'

export const useChatConnection = (consultation_id: string) => {
  const [messages, setMessages] = useState<ConsultationMessage[]>([])
  const [showTcleModal, setShowTcleModal] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [message, setMessage] = useState('')
  const { toast } = useToastContext()

  const pollMessages = useCallback(async () => {
    try {
      const newMessages = await ConsultationService.getMessagesByConsultationId(consultation_id, {
        skip: messages.length
      })

      if (newMessages.length > 0) {
        setMessages(oldMessages => [...oldMessages, ...newMessages])
      }
    } catch (error) {
      if ((error as Error).message !== 'TCLE_NOT_FOUND') {
        toast.error('Erro ao obter mensagens.')
      } else {
        setShowTcleModal(true)
      }
    } finally {
      setLoading(false)
    }
  }, [consultation_id, messages, toast])

  useEffect(() => {
    const interval = setInterval(pollMessages, 3000)

    return () => {
      clearInterval(interval)
    }
  }, [pollMessages])

  const handleSendMessage = async () => {
    if (message.trim()) {
      await ConsultationService.saveMessage(consultation_id, message)
      setLoading(true)
      setMessage('')
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      handleSendMessage()
    }
  }

  const reloadMessages = () => {
    setShowTcleModal(false)
    setMessages([])
    setLoading(true)
  }

  return {
    message,
    messages,
    isLoading,
    showTcleModal,
    setMessage,
    reloadMessages,
    handleSendMessage,
    handleKeyPress
  }
}
