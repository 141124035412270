const consultation_form_ptBR = {
  title_update_elective_care: 'Editar atendimento eletivo',
  title_elective_care: 'Novo atendimento eletivo',
  title_update_emergency_care: 'Editar pronto atendimento',
  title_emergency_care: 'Novo pronto atendimento',
  title_update_chat: 'Editar atendimento por chat',
  title_chat: 'Novo atendimento por chat',

  subtitle: 'Visualize e gerencie os atendimentos',

  selects: {
    sexes: {
      masculine: 'Masculino',
      feminine: 'Feminino',
      other: 'Outros'
    }
  },

  fields: {
    patient_name: 'Nome do paciente',
    patient_age: 'Idade do paciente',
    patient_sex: 'Sexo do paciente',
    type: 'Tipo de atendimento',
    client: 'Cliente',
    unit: 'Unidade',
    patient: 'Paciente',
    symptoms: 'Sintomas',
    clinical_history: 'Histórico clínico',
    specialty: 'Especialidade',
    requester: 'Solicitante',
    without_patient: 'Paciente não identificado'
  },
  without_patient_alert:
    'Essa consulta não será adicionada ao protuário do paciente, pois o paciente não foi identificado com seu documento',
  placeholder: {
    symptoms: 'Descreva os sintomas do paciente',
    clinical_history: 'Descreva o histórico clínico do paciente brevemente'
  },
  actions: {
    select_an_option: 'Selecione uma opção',
    select_pacient: 'Selecionar paciente',
    save: 'Salvar atendimento',
    cancel: 'Cancelar',
    exclude: 'Excluir atendimento',
    confirm: 'Confirmar'
  },

  modal_confirmation_update_consultation: {
    title: 'Editar atendimento',
    decription: 'Tem certeza que deseja editar esse atendimento?',
    decription_patient: 'Para atualizar o atendimento, digite o nome do paciente',
    decription_without_patient:
      'Para salvar o atualizar o atendimento, digite <br /> <b>"Sem paciente"</b>',
    input_placeholder: 'Nome do paciente'
  }
}

export default consultation_form_ptBR
