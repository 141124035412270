import { useEffect, useState } from 'react'
import Modal from '../modal'
import { Client, Unit } from '../../types'
import { useTranslation } from 'react-i18next'
import ClientList from './client-list'
import Button from '../button'
import UnitList from './units-list'

export type UnitItem = { unit_id?: string; unit?: Unit }
export type ClientItem = { client_id?: string; client?: Client; units?: UnitItem[] }

type Props = {
  isOpen: boolean
  multiple?: boolean
  clients: ClientItem[]
  onClose: () => void
  onSelectOne?: (unit: Unit) => void
  onSelectMultiple?: (clients: ClientItem[], units?: string[]) => void
  isSearchable?: boolean
}

const SelectUnitsModal: React.FC<Props> = ({
  isOpen,
  clients,
  multiple,
  onClose,
  onSelectOne,
  onSelectMultiple,
  isSearchable = false
}) => {
  const { t } = useTranslation('user_form')
  const [selectedClients, setSelectedClients] = useState<Client[]>([])
  const [selectedUnits, setSelectedUnits] = useState<Unit[]>([])
  const [showUnities, setShowUnities] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setSelectedClients(clients.map(({ client }) => client!))
      setSelectedUnits(
        clients.reduce(
          (acc, item) => [...acc, ...item.units!.map(({ unit }) => unit!)],
          [] as Unit[]
        )
      )
    } else {
      setSelectedClients([])
      setSelectedUnits([])
    }
  }, [isOpen, clients])

  const handleContinue = () => {
    if (showUnities) {
      if (!multiple && onSelectOne && selectedUnits[0]) {
        onSelectOne(selectedUnits[0])
      }
      if (onSelectMultiple) {
        onSelectMultiple(
          selectedClients.map(client => {
            return {
              ...(clients.find(item => item.client_id === client.id) || {}),
              client,
              client_id: client.id,
              units: selectedUnits
                .filter(({ client_id }) => client_id === client.id)
                .map(unit => ({ unit }))
            }
          }),
          selectedUnits.map(unit => unit.id).filter((id): id is string => Boolean(id))
        )
      }

      setShowUnities(false)
      onClose()
    } else {
      setShowUnities(true)
    }
  }

  const isInvalid = (showUnities ? selectedUnits : selectedClients).length === 0

  const handleClose = () => {
    onClose()
    setShowUnities(false)
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={t(showUnities ? 'fields.select_units_modal' : 'fields.select_clients_modal')}
    >
      <div className='mx-[-16px] max-h-[10dvh] min-h-[500px] min-w-[400px] overflow-y-auto px-4 pb-20'>
        {showUnities ? (
          <UnitList
            multiple={multiple}
            selectedUnits={selectedUnits}
            selectedClients={selectedClients}
            handleSelectedUnits={setSelectedUnits}
          />
        ) : (
          <ClientList
            multiple={multiple}
            selectedClients={selectedClients}
            handleSelectedClients={setSelectedClients}
            isSearchable={isSearchable}
          />
        )}
      </div>
      <div className='absolute bottom-0 left-0 right-0 z-20 bg-white px-6 py-4 shadow-footer'>
        <Button
          type='button'
          width='full'
          className='ml-auto'
          onClick={handleContinue}
          label={t('actions.continue')}
          state={isInvalid ? 'disable' : 'enable'}
        />
      </div>
    </Modal>
  )
}

export default SelectUnitsModal
