const formatPhoneNumber = (value: string) => {
  value = value.replace(/\D/g, '').substring(0, 11)

  if (value.length === 0) {
    return ''
  } else if (value.length <= 2) {
    value = value.replace(/^(\d{0,2})/, '($1')
  } else if (value.length <= 6) {
    value = value.replace(/^(\d{2})(\d{0,4})/, '($1) $2')
  } else if (value.length <= 10) {
    value = value.replace(/^(\d{2})(\d{4})(\d{0,4})/, '($1) $2-$3')
  } else {
    value = value.replace(/^(\d{2})(\d{5})(\d{0,4})/, '($1) $2-$3')
  }

  return value
}

const formatCNPJ = (value: string) => {
  value = value.replace(/\D/g, '')

  value = value.substring(0, 14)

  value = value.replace(/^(\d{2})(\d)/, '$1.$2')
  value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
  value = value.replace(/\.(\d{3})(\d)/, '.$1/$2')
  value = value.replace(/(\d{4})(\d)/, '$1-$2')

  return value
}
export const formatCPF = (value: string) => {
  value = value.replace(/\D/g, '')

  // Limita a 11 caracteres
  value = value.substring(0, 11)

  // Aplica a máscara para CPF: 000.000.000-00
  value = value.replace(/(\d{3})(\d)/, '$1.$2')
  value = value.replace(/(\d{3})(\d)/, '$1.$2')
  value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2')

  return value
}
const formatCEP = (value: string) => {
  value = value.replace(/\D/g, '').substring(0, 8) // Remove tudo que não é dígito e limita a 8 caracteres

  if (value.length <= 5) {
    value = value.replace(/^(\d{0,5})/, '$1') // Primeiros cinco dígitos
  } else {
    value = value.replace(/^(\d{5})(\d{0,3})/, '$1-$2') // Formata como CEP
  }

  return value
}
export const formatCNS = (value: string) => {
  value = value.replace(/\D/g, '')
  value = value.substring(0, 15)

  value = value.replace(/(\d{3})(\d)/, '$1 $2')
  value = value.replace(/(\d{3}) (\d{4})(\d)/, '$1 $2 $3')
  value = value.replace(/(\d{3}) (\d{4}) (\d{4})(\d)/, '$1 $2 $3 $4')

  return value
}

const formatCNES = (value: string) => {
  value = value.replace(/\D/g, '')
  value = value.substring(0, 7)

  return value
}

const formatPassport = (value: string) => {
  value = value.replace(/[^a-zA-Z0-9]/g, '')

  value = value.substring(0, 8)

  value = value.replace(/^([a-zA-Z]{0,2})(\d{0,6})/, (_, letters, numbers) => {
    return `${letters.toUpperCase()}${numbers}`
  })

  return value
}

export const formatValue = (type: string, value: string) => {
  if (type === 'name') {
    return value
      .split(' ')
      .map((word: string) => {
        if (!word) return word
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      })
      .join(' ')
  }
  if (type === 'cpf') {
    return formatCPF(value)
  }
  if (type === 'phone') {
    return formatPhoneNumber(value)
  }
  if (type === 'cnpj') {
    return formatCNPJ(value)
  }
  if (type === 'cep') {
    return formatCEP(value)
  }
  if (type === 'cns') {
    return formatCNS(value)
  }
  if (type === 'cnes') {
    return formatCNES(value)
  }
  if (type === 'passport') {
    return formatPassport(value)
  }
  if (type === 'number') {
    return value.replace(/[^0-9]/g, '')
  }
  if (type === 'cpf_cns') {
    return formatCpfCns(value)
  }

  return value
}

export const formatCpfCns = (value: string) => {
  const digits = value.replace(/\D/g, '')

  if (digits.length <= 11) {
    return formatCPF(value)
  } else {
    return formatCNS(value)
  }
}
