import { useQuery } from '@tanstack/react-query'
import PatientService from '../services/patient.service'

export const useGetPatient = (document: string, enable: boolean = true) => {
  return useQuery({
    queryKey: ['patientDetail', document],
    queryFn: () => PatientService.getByDocument(document),
    enabled: enable
  })
}

export const useGetConsultations = (document: string) => {
  return useQuery({
    queryKey: ['patientDetailConsultations', document],
    queryFn: () => PatientService.getConsultation(document)
  })
}

export const useGetClinicalInformation = (document: string) => {
  return useQuery({
    queryKey: ['getClinicalInformation', document],
    queryFn: () => PatientService.getClinicalInformation(document)
  })
}

export const useGetDocuments = (document: string) => {
  return useQuery({
    queryKey: ['patientDetailConsultations', document],
    queryFn: () => PatientService.getDocuments(document)
  })
}
