/* eslint-disable @typescript-eslint/no-unused-vars */
import SelectUnitsModal, { ClientItem, UnitItem } from '../../../components/select-units-modal'
import { useIndividualInviteFormik } from '../../../hooks/forms/useInvite'
import { useGetPatient } from '../../../hooks/usePatient'
import BlankCard from '../../../components/blank-card'
import React, { useEffect, useState } from 'react'
import InputField from '../../../components/input'
import Button from '../../../components/button'
import Avatar from '../../../components/avatar'
import { useTranslation } from 'react-i18next'
import Modal from '../../../components/modal'
import { X } from '@phosphor-icons/react'

function sanitize(value: string | null | undefined) {
  if (!value) return ''
  return value.replace(/[.,-\s]/g, '')
}

interface Props {
  open: boolean
  onClose: () => void
  onSuccess: () => void
}

export const InviteModalIndividual: React.FC<Props> = ({ open, onClose, onSuccess }) => {
  const { t } = useTranslation('patient_list')
  const formik = useIndividualInviteFormik(() => {
    onSuccess()
  })

  const [showUnitsModal, setShowUnitsModal] = useState(false)
  const [selectedClients, setSelectedClients] = useState<ClientItem[] | undefined | null>(undefined)

  const cpfSanitized = sanitize(formik.values.cpf)
  const cnsSanitized = sanitize(formik.values.cns_number)

  const shouldFetchPatient = cpfSanitized.length >= 11 || cnsSanitized.length > 11
  const patientId = cpfSanitized.length >= 11 ? cpfSanitized : cnsSanitized

  const { data: patient, isFetching, isLoading } = useGetPatient(patientId, shouldFetchPatient)

  const isPatientRegistered = Boolean(patient?.id)
  const inputsDisabled = !shouldFetchPatient || isFetching || isLoading || isPatientRegistered

  useEffect(() => {
    if (patient?.id) {
      formik.setFieldValue('email', patient.email)
      formik.setFieldValue('name', patient.name)
      formik.setFieldValue('cns_number', patient.cns_number)
      formik.setFieldValue('cpf', patient.cpf)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient])

  const onRemoveUnit = (item: ClientItem, unitItem: UnitItem) => {
    setSelectedClients(prevSelected => {
      if (!prevSelected) return prevSelected

      const updatedClients = prevSelected.map(clientItem => {
        if (clientItem.client?.id === item.client?.id) {
          const updatedUnits = clientItem.units?.filter(u => u.unit?.id !== unitItem.unit?.id) || []
          return { ...clientItem, units: updatedUnits }
        }
        return clientItem
      })

      const updatedUnitIds = updatedClients.flatMap(clientItem =>
        (clientItem.units || []).map(u => u.unit?.id)
      )
      formik.setFieldValue('units', updatedUnitIds)
      formik.setFieldValue('clients', updatedClients)

      return updatedClients
    })
  }

  return (
    <Modal
      isOpen={open}
      onClose={() => {
        onClose()
        formik.setValues({
          cpf: '',
          cns_number: '',
          email: '',
          name: '',
          units: [],
          clients: []
        })
      }}
      onBack={() => onClose()}
      className='max-h-[65vh] w-full max-w-[95vw] overflow-y-scroll sm:w-[500px]'
    >
      <div className='my-2 text-heading-xs'>{t(`invite_modal_individual.title`)}</div>
      <p className='text-body-md text-neutralContent-quartenary'>
        {t(`invite_modal_individual.description`)}
      </p>

      <div className='mt-4 flex flex-col gap-4'>
        <div className='flex gap-2'>
          <InputField
            label={t('invite_modal_individual.fields.cpf')}
            id='cpf'
            name='cpf'
            type='cpf'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.cpf}
            state={formik.touched.cpf && formik.errors.cpf ? 'error' : 'default'}
            hint={formik.touched.cpf && formik.errors.cpf ? formik.errors.cpf : undefined}
          />

          <InputField
            label={t('invite_modal_individual.fields.cns')}
            placeholder={t('invite_modal_individual.fields.cns')}
            id='cns_number'
            name='cns_number'
            type='cns'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.cns_number}
            state={formik.touched.cns_number && formik.errors.cns_number ? 'error' : 'default'}
            hint={
              formik.touched.cns_number && formik.errors.cns_number
                ? formik.errors.cns_number
                : undefined
            }
          />
        </div>
        <div className='body-sm'>{t('invite_modal_individual.warn')}</div>

        <InputField
          label={t('invite_modal_individual.fields.name')}
          placeholder={t('invite_modal_individual.fields.name')}
          id='name'
          name='name'
          type='name'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          state={inputsDisabled ? 'disabled' : 'default'}
          isLoading={isFetching}
          hint={formik.touched.name && formik.errors.name ? formik.errors.name : undefined}
        />

        <InputField
          label={t('invite_modal_individual.fields.email')}
          placeholder={t('invite_modal_individual.fields.email')}
          id='email'
          name='email'
          type='email'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          state={inputsDisabled ? 'disabled' : 'default'}
          isLoading={isFetching}
          hint={formik.touched.email && formik.errors.email ? formik.errors.email : undefined}
        />

        <div>
          <div className='text-heading-xs'>{t('invite_modal_individual.fields.units.label')}</div>
          <div className='mb-4 text-body-md text-neutralContent-secondary'>
            {t('invite_modal_individual.fields.units.description')}
          </div>

          {selectedClients?.map(item => {
            if (item.client) {
              return (
                <div key={item.client.id}>
                  <div className='mb-4 flex items-center gap-2 text-label-md'>
                    <Avatar size='xs' imageID={item.client.image_id} name={item.client.name} />
                    {item.client.name}
                  </div>
                  <BlankCard>
                    {item.units?.map((unitItem, index) => (
                      <React.Fragment key={unitItem.unit?.id}>
                        <div className='flex items-center justify-between py-1'>
                          <div className='text-body-md text-neutralContent-secondary'>
                            {unitItem.unit?.name}
                          </div>
                          <X onClick={() => onRemoveUnit(item, unitItem)} />
                        </div>
                        {item.units && index !== item.units?.length - 1 && (
                          <div className='my-2 h-[1px] bg-neutralBorder-default' />
                        )}
                      </React.Fragment>
                    ))}
                  </BlankCard>
                </div>
              )
            }
            return null
          })}

          <Button
            label={t('actions.add_unit')}
            size='sm'
            variant='secondary'
            onClick={() => setShowUnitsModal(true)}
            className='mt-4'
          />
        </div>
      </div>

      <SelectUnitsModal
        multiple
        isOpen={showUnitsModal}
        clients={formik.values.clients || []}
        onClose={() => setShowUnitsModal(false)}
        onSelectMultiple={(client, units) => {
          formik.setFieldValue('clients', client)
          formik.setFieldValue('units', units)
          setSelectedClients(client as ClientItem[])
        }}
        isSearchable
      />

      <div className='mt-4 flex border-t pt-4'>
        <Button
          size='md'
          width={'full'}
          label={t('actions.send_invite')}
          variant='primary'
          onClick={formik.handleSubmit}
        />
      </div>
    </Modal>
  )
}
