/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, useEffect, useState } from 'react'
import { useClientProfileFormik } from '../../../hooks/forms/useClientForm'
import SpecialtyModal from '../../../components/modal/specialty-modal'
import HourSelect from '../../../components/dropdown/hour-select-v2'
import { removeDuplicates } from '../../../helpers/removeDuplicates'
import ProfilesModal from '../../../components/modal/profiles-modal'
import ContentFooter from '../../../components/content/footer'
import StepperNumber from '../../../components/stepper-number'
import ContentForm from '../../../components/content/form'
import TagSelector from '../../../components/tag-selector'
import ColorPicker from '../../../components/color-picker'
import { useToastContext } from '../../../contexts/toast'
import { Client, ClientSpecialty } from '../../../types'
import BlankCard from '../../../components/blank-card'
import { UploadSimple } from '@phosphor-icons/react'
import Checkbox from '../../../components/checkbox'
import InputField from '../../../components/input'
import Avatar from '../../../components/avatar'
import Button from '../../../components/button'
import { useTranslation } from 'react-i18next'
import { FormikProps, useFormik } from 'formik'
import Modal from '../../../components/modal'
import * as Yup from 'yup'

type Props = {
  onSuccess: (values: Partial<Client>) => void
  onCancel: () => void
  initialValues?: Partial<Client>
  isEdit?: boolean
  loading?: boolean
}

const ClientProfileForm: React.FC<Props> = ({
  onSuccess,
  initialValues,
  isEdit = false,
  loading
}) => {
  const { t } = useTranslation('client_form')
  const clientFormik = useClientProfileFormik(onSuccess, initialValues)
  const { toast } = useToastContext()
  const [showSpecialtiesSettings, setShowSpecialtiesSettings] = useState(false)
  const [isModalSpecialtyOpen, setIsModalSpecialtyOpen] = useState(false)
  const [isModalProfilesOpen, setIsModalProfilesOpen] = useState(false)
  const [uploadStatus, setUploadStatus] = useState<'idle' | 'loading' | 'completed' | 'error'>(
    'idle'
  )
  const [imageName, setImageName] = useState<string>('')
  const [displaySpecialties, setDisplaySpecialties] = useState<any[]>(
    clientFormik.values.specialties || []
  )
  const [displayProfiles, setDisplayProfiles] = useState<any[]>(clientFormik.values.profiles || [])

  useEffect(() => {
    if (initialValues) {
      setDisplaySpecialties(
        removeDuplicates(
          initialValues.specialties?.map(s => ({ id: s.specialty_id, name: s.specialty?.name })) ||
            [],
          'id'
        )
      )
      setDisplayProfiles(
        removeDuplicates(
          initialValues.profiles?.map(p => ({ id: p.profile_id, name: p.profile?.name })) || [],
          'id'
        )
      )
    }
  }, [initialValues])

  const handleSuccessSpecialties = (selectedValues: any[]) => {
    const uniqueSelectedValues = removeDuplicates(selectedValues, 'id')
    setDisplaySpecialties(uniqueSelectedValues)
    clientFormik.setFieldValue(
      'specialties',
      uniqueSelectedValues.map(value => ({ specialty_id: value.id }))
    )
    setIsModalSpecialtyOpen(false)
  }

  const handleSuccessProfiles = (selectedValues: any[]) => {
    const uniqueSelectedValues = removeDuplicates(selectedValues, 'id')
    setDisplayProfiles(uniqueSelectedValues)
    clientFormik.setFieldValue(
      'profiles',
      uniqueSelectedValues.map(value => ({ profile_id: value.id }))
    )
    setIsModalProfilesOpen(false)
  }

  const handleRemoveItem = async (id: string, type: 'specialty' | 'profile') => {
    if (type === 'specialty') {
      setDisplaySpecialties(displaySpecialties.filter(s => s.id !== id))
      await clientFormik.setFieldValue(
        'specialties',
        clientFormik.values.specialties?.filter(s => s.specialty_id !== id)
      )
    } else {
      setDisplayProfiles(displayProfiles.filter(p => p.id !== id))
      await clientFormik.setFieldValue(
        'profiles',
        clientFormik.values.profiles?.filter(p => p.profile_id !== id)
      )
    }
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp']

      if (!validImageTypes.includes(file.type)) {
        setUploadStatus('error')
        toast.error('Por favor, selecione um arquivo de imagem válido (JPEG, PNG, GIF ou WEBP).')
        return
      }

      if (file.size > 512000) {
        setUploadStatus('error')
        toast.error('O arquivo selecionado excede o tamanho máximo de 500 KB.')
        return
      }

      // verifca  a resolução, crie um objeto Image para carregar a imagem.
      setUploadStatus('loading')
      const tempUrl = URL.createObjectURL(file)
      const img = new Image()
      img.src = tempUrl

      img.onload = async () => {
        if (img.width > 162 || img.height > 50) {
          setUploadStatus('error')
          toast.error('A resolução da imagem excede o máximo permitido de 162x50 pixels.')
          // liberando a url temporaria -boa prática, mas opcional
          URL.revokeObjectURL(tempUrl)
          return
        }

        // seta a imagem, em caso de sucesso (passou pelas regras)
        setImageName(file.name)
        try {
          await clientFormik.setFieldValue('image', tempUrl)
          setUploadStatus('completed')
        } catch {
          clientFormik.setFieldValue('image', '')
          setUploadStatus('error')
        }
      }

      // verifica se tem algo de errado com a imagem, como por exemplo, arquivo corrompido
      img.onerror = () => {
        setUploadStatus('error')
        toast.error('Não foi possível carregar a imagem. Verifique se o arquivo está corrompido.')
        URL.revokeObjectURL(tempUrl)
      }
    }
  }

  return (
    <form onSubmit={clientFormik.handleSubmit}>
      <ContentForm hasSubtitle hasBackButton>
        <UnitLimitField clientFormik={clientFormik as FormikProps<any>} t={t} />

        <TagSelectorField
          label={t('fields.profile.specialties')}
          items={displaySpecialties}
          onSelect={() => setIsModalSpecialtyOpen(true)}
          onRemove={item => handleRemoveItem(item.id, 'specialty')}
          buttonLabel={t('actions.select_specialties')}
        >
          {clientFormik.values.specialties && clientFormik.values.specialties.length > 0 && (
            <div className='mx-[-16px] mt-4 flex w-[calc(100%+32px)] flex-col border-t-[1px] border-neutralBorder-default p-4'>
              <span className='mb-2 text-label-md text-neutralContent-primary'>
                Configurar horários
              </span>
              <span className='text-body-sm text-neutralContent-secondary'>
                Configure os horários disponíveis para prontoatendimento e atendimento por chat,
                para unidades que tenham esse tipo de atendimento disponível.
              </span>
              <Button
                size='sm'
                type='button'
                variant='secondary'
                className='mt-4 w-fit'
                label='Configurar horários'
                onClick={() => setShowSpecialtiesSettings(true)}
              />
              <SpecialtyEmergenceScaleModal
                onSubmit={(index, values) =>
                  clientFormik.setFieldValue(`specialties.${index}`, {
                    ...clientFormik.values.specialties[index],
                    ...values
                  })
                }
                isOpen={showSpecialtiesSettings}
                onClose={() => setShowSpecialtiesSettings(false)}
                specialties={clientFormik.values.specialties}
              />
            </div>
          )}
        </TagSelectorField>

        <CheckboxField
          isChecked={clientFormik.values.allow_profile_access_to_client!}
          onChange={async val =>
            await clientFormik.setFieldValue('allow_profile_access_to_client', val)
          }
          label={t('fields.profile.allow_profile_access_to_client')}
        />

        {clientFormik.values.allow_profile_access_to_client && (
          <TagSelectorField
            label={t('fields.profile.allowed_profiles')}
            items={displayProfiles}
            onSelect={() => setIsModalProfilesOpen(true)}
            onRemove={item => handleRemoveItem(item.id, 'profile')}
            buttonLabel={t('actions.select_profiles')}
          />
        )}

        <BlankCard className='col-span-2 flex flex-col gap-2'>
          <span className='font-semibold'>{'Dominio (DNS)'}</span>
          <InputField
            id='domain'
            name='domain'
            onChange={clientFormik.handleChange}
            value={clientFormik.values.domain}
            placeholder='www.dominio.com.br'
          />
        </BlankCard>

        <BlankCard className='col-span-2'>
          <AvatarUploadField
            client={initialValues}
            imageName={imageName}
            imageUrl={clientFormik.values.image}
            uploadStatus={uploadStatus}
            onFileChange={handleFileChange}
            t={t}
          />
          <ColorPickerField
            value={clientFormik.values.primary_color!}
            onChange={async value => await clientFormik.setFieldValue('primary_color', value)}
            t={t}
          />
        </BlankCard>

        <SpecialtyModal
          isOpen={isModalSpecialtyOpen}
          selectedValues={displaySpecialties}
          onClose={() => setIsModalSpecialtyOpen(false)}
          onSuccess={handleSuccessSpecialties}
        />
        <ProfilesModal
          isOpen={isModalProfilesOpen}
          selectedValues={displayProfiles}
          onClose={() => setIsModalProfilesOpen(false)}
          onSuccess={handleSuccessProfiles}
        />
        <ContentFooter className='justify-end gap-2'>
          <Button
            state={!clientFormik.isValid ? 'disable' : 'enable'}
            type='submit'
            size='md'
            label={isEdit ? t('actions.save') : t('actions.create_client')}
            isLoading={loading && (loading || clientFormik.isSubmitting)}
          />
        </ContentFooter>
      </ContentForm>
    </form>
  )
}

const UnitLimitField: React.FC<{
  clientFormik: FormikProps<Partial<Client>>
  t: (key: string) => string
}> = ({ clientFormik, t }) => (
  <div className='col-span-2 flex items-center justify-between'>
    <div>
      <label className='text-heading-md'>{t('fields.profile.units_limit')}</label>
      <p className='text-sm'>{t('fields.profile.units_limit_hint')}</p>
    </div>
    <div>
      <StepperNumber
        hint={
          clientFormik.touched.units_limit && clientFormik.errors.units_limit
            ? clientFormik.errors.units_limit
            : ''
        }
        name='units_limit'
        value={clientFormik.values.units_limit ?? 0}
        onChange={clientFormik.handleChange}
        onBlur={clientFormik.handleBlur}
        min={1}
        max={100}
        step={1}
        state={'default'}
      />
    </div>
  </div>
)

const CheckboxField: React.FC<{
  isChecked: boolean
  onChange: (val: boolean) => void
  label: string
}> = ({ isChecked, onChange, label }) => (
  <div className='mt-2 flex items-center gap-2'>
    <Checkbox size='md' isChecked={isChecked} onClick={onChange} />
    <span className='text-label-lg font-bold text-neutralContent-primary'>{label}</span>
  </div>
)

const TagSelectorField: React.FC<{
  label: string
  items: ClientSpecialty[]
  onSelect: () => void
  onRemove: (item: { id: string; name: string }) => void
  buttonLabel: string
  children?: ReactNode
}> = ({ label, items, onSelect, onRemove, buttonLabel, children }) => (
  <div className='col-span-2'>
    <TagSelector
      label={label}
      selectedItems={items}
      onSelect={onSelect}
      onRemove={onRemove}
      buttonLabel={buttonLabel}
    >
      {children}
    </TagSelector>
  </div>
)

const AvatarUploadField: React.FC<{
  client?: Partial<Client>
  imageName: string
  imageUrl?: string
  uploadStatus: 'idle' | 'loading' | 'completed' | 'error'
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  t: (key: string) => string
}> = ({ imageName, imageUrl, client, uploadStatus, onFileChange, t }) => (
  <div className='mb-8 flex items-center justify-between'>
    <div className='flex items-center'>
      <Avatar className='mr-2' imageUrl={imageUrl} client={client} size={'sm'} />
      <div className='flex flex-col'>
        <span className='text-label-md text-neutralContent-primary'>
          {imageName || t('fields.profile.no_image_selected')}
        </span>
        <span className='text-body-sm text-neutralContent-secondary'>
          {uploadStatus === 'loading' && t('fields.profile.uploading')}
          {uploadStatus === 'completed' && t('fields.profile.completed')}
          {uploadStatus === 'error' && t('fields.profile.error')}
        </span>
      </div>
    </div>

    <input
      type='file'
      id='avatar-upload'
      style={{ display: 'none' }}
      onChange={onFileChange}
      accept='image/*'
    />
    <Button
      type='button'
      label={imageName ? t('actions.change_file') : t('actions.select_file')}
      onClick={() => document.getElementById('avatar-upload')?.click()}
      variant='secondary'
      size={'sm'}
      iconLeft={<UploadSimple size={18} />}
    />
  </div>
)

const ColorPickerField: React.FC<{
  value: string
  onChange: (value: string) => void
  t: (key: string) => string
}> = ({ value, onChange, t }) => (
  <div className='flex items-center justify-between'>
    <div className='flex flex-col'>
      <span className='text-label-md text-neutralContent-primary'>
        {t('fields.profile.primary_color')}
      </span>
      <span className='text-body-sm text-neutralContent-secondary'>
        {t('fields.profile.primary_color_hint')}
      </span>
    </div>
    <ColorPicker id='color_client' value={value} onChange={onChange} label='' />
  </div>
)

const SpecialtyEmergenceScaleModal: React.FC<{
  isOpen: boolean
  onSubmit: (index: number, values: ClientSpecialty) => void
  specialties: ClientSpecialty[]
  onClose: VoidFunction
}> = ({ isOpen, specialties, onClose, onSubmit }) => {
  const [indexToUpdateScale, setIndexToUpdateScale] = useState<number>()
  const WEEK_DAYS = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S']

  const handleClose = () => {
    onClose()
  }

  const formik = useFormik({
    onSubmit: values => {
      onSubmit(indexToUpdateScale!, values)
      setIndexToUpdateScale(undefined)
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      emergency_days: Yup.array().required(''),
      emergency_time_start: Yup.string().required(''),
      emergency_time_end: Yup.string().required('')
    }),
    validateOnMount: true,
    initialValues:
      indexToUpdateScale !== undefined
        ? specialties[indexToUpdateScale]
        : ({
            emergency_days: [],
            emergency_time_end: '',
            emergency_time_start: ''
          } as ClientSpecialty)
  })

  const handleClickDay = (day: number) => {
    if (formik.values.emergency_days?.includes(day)) {
      formik.setFieldValue(
        'emergency_days',
        formik.values?.emergency_days?.filter(item => item !== day) || []
      )
    } else {
      formik.setFieldValue(
        'emergency_days',
        [...(formik.values.emergency_days || []), day].sort((a, b) => a - b)
      )
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onBack={indexToUpdateScale !== undefined ? () => setIndexToUpdateScale(undefined) : undefined}
      onClose={handleClose}
      className='w-[500px] overflow-visible'
    >
      {indexToUpdateScale !== undefined ? (
        <div className='mt-[-10px] flex flex-col'>
          <span className='text-heading-sm'>
            {specialties[indexToUpdateScale]?.specialty?.name}
          </span>

          <span className='mb-2 mt-6 text-label-md text-neutralContent-primary'>
            {'Dias da semana'}
          </span>

          <div className='flex gap-3'>
            {WEEK_DAYS.map((d, index) => (
              <Button
                label={d}
                key={index}
                type='button'
                onClick={() => handleClickDay(index)}
                variant={
                  formik.values.emergency_days?.some(item => item === index) ? 'primary' : 'neutral'
                }
              />
            ))}
          </div>

          <div className='mb-2 mt-6 text-label-md text-neutralContent-primary'>Horário</div>

          <div className='flex items-center gap-2'>
            <HourSelect
              label={'Início'}
              value={formik.values.emergency_time_start || ''}
              onChange={value => formik.setFieldValue('emergency_time_start', value)}
            />
            <HourSelect
              label={'Fim'}
              min={formik.values.emergency_time_start}
              value={formik.values.emergency_time_end || ''}
              onChange={value => formik.setFieldValue('emergency_time_end', value)}
            />
          </div>

          <Button
            type='button'
            className='mt-10'
            label='Salvar'
            variant={formik.isValid ? 'primary' : 'neutral'}
            onClick={formik.submitForm}
          />
        </div>
      ) : (
        <div className='flex flex-col'>
          <div className='mt-[-40px] flex flex-col'>
            <span className='text-heading-sm'>{'Configurar horários'}</span>
            <span className='text-body-md text-neutralContent-secondary'>
              {
                'Adicione, para cada especialidade, os horários disponíveis para pacientes marcarem consultas de prontoatendimento.'
              }
            </span>
          </div>
          <div className='mt-6 flex max-h-[50vh] flex-col overflow-y-scroll pb-5'>
            {specialties.map((item, index) => (
              <div
                key={item.id}
                className='flex w-full items-center justify-between border-b-[1px] py-2'
              >
                <div className='flex flex-col'>
                  <span className='text-label-md text-neutralContent-primary'>
                    {item?.specialty?.name}
                  </span>
                  <span className='text-body-sm text-neutralContent-secondary'>
                    {item.emergency_days && item.emergency_days.length > 0
                      ? `${item.emergency_days.map(day => WEEK_DAYS[day]).join(',')} | ${item.emergency_time_start}-${item.emergency_time_end}`
                      : 'Adicione uma escala'}
                  </span>
                </div>
                <Button
                  variant='secondary'
                  label='Adicionar'
                  onClick={() => setIndexToUpdateScale(index)}
                  type='button'
                />
              </div>
            ))}
          </div>
          <Button
            type='button'
            label='Fechar'
            variant='secondary'
            size='md'
            width='full'
            className='mt-5 w-full'
            onClick={handleClose}
          />
        </div>
      )}
    </Modal>
  )
}

export default ClientProfileForm
