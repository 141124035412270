import Content from '../../../components/content'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetPatient } from '../../../hooks/usePatient'
import Avatar from '../../../components/avatar'
import PatientTabs from './tabs'
import Button from '../../../components/button'
import { useTranslation } from 'react-i18next'
import { ROUTES } from '../../../routes/path'
import { Download } from '@phosphor-icons/react'
import { useState } from 'react'
import PatientService from '../../../services/patient.service'

const PatientDetailPage = () => {
  const { id } = useParams<{ id: string }>()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation('patient_detail')
  const navigate = useNavigate()

  const { data, isFetching } = useGetPatient(id as string)

  const downloadHistoric = async () => {
    setLoading(true)
    try {
      const response = await PatientService.downloadHistoric(id!)

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `historic.pdf`)
      document.body.appendChild(link)
      link.click()

      link.parentNode?.removeChild(link)
    } catch (error) {
      console.log(error)
    }

    setLoading(false)
  }

  return (
    <Content>
      {!isFetching ? (
        <div>
          {/* PATIENT NAME */}
          <div className='flex items-center justify-between'>
            <div className='flex items-center gap-4'>
              <Avatar name={data?.name} />
              <span className='text-heading-xl text-neutralContent-primary'>{data?.name}</span>
            </div>
            <div className='flex items-center gap-2'>
              <Button
                size='sm'
                variant='primary'
                isLoading={loading}
                iconLeft={<Download />}
                onClick={downloadHistoric}
                label={t('actions.download_historic')}
              />
              <Button
                label={t('actions.update')}
                variant='secondary'
                size='sm'
                onClick={() => {
                  navigate(ROUTES.patient.form.replace(':id', id as string))
                }}
              />
            </div>
          </div>

          {/* TABS */}
          <div className='mt-7'>
            <PatientTabs id={id as string} patientData={data} />
          </div>
        </div>
      ) : (
        <>
          <div className='flex items-center gap-4'>
            <div className='w- h-12 w-12  animate-pulse rounded-full bg-gray-200' />
            <div className='h-4 w-1/2 animate-pulse rounded-md bg-gray-200' />
          </div>

          <div className='mt-12 flex items-center gap-5'>
            {Array.from({ length: 5 }).map((_, index) => (
              <div
                key={index}
                className='h-[26px] w-[70px] animate-pulse rounded-full  bg-gray-200 px-4 py-2'
              />
            ))}
          </div>
        </>
      )}
    </Content>
  )
}

export default PatientDetailPage
