import { DropdownOption } from '../components/dropdown'

const minutes = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55']

export const generateHourOptions = (min?: string, max?: string): DropdownOption[] => {
  const options: DropdownOption[] = []
  const startHour = min ? Number(min.split(':')[0]) : 7
  const endHour = max ? Number(max.split(':')[0]) : 23
  const startMinute = min ? minutes.indexOf(min.split(':')[1]) : 0
  const endMinute = max ? minutes.indexOf(max.split(':')[1]) : minutes.length - 1

  for (let h = startHour; h <= endHour; h++) {
    const hour = h.toString().padStart(2, '0')

    if (h === startHour) {
      for (let m = startMinute >= 0 ? startMinute : 0; m < minutes.length; m++) {
        options.push({ value: `${hour}:${minutes[m]}`, label: `${hour}:${minutes[m]}` })
      }
    } else if (h === endHour) {
      for (let m = 0; m <= endMinute; m++) {
        options.push({ value: `${hour}:${minutes[m]}`, label: `${hour}:${minutes[m]}` })
      }
    } else {
      for (let m = 0; m < minutes.length; m++) {
        options.push({ value: `${hour}:${minutes[m]}`, label: `${hour}:${minutes[m]}` })
      }
    }
  }

  return options
}
