const profile_ptBR = {
  title: 'Perfis de acesso',
  permission_groups: {
    admin: 'Administrador',
    registrations: 'Cadastros',
    listings: 'Listagens',
    management: 'Gerencimento',
    services: 'Atendimentos',
    reports: 'Relatórios',
    documents: 'Documentos'
  },
  permissions: {
    register_all_new_clients_and_units: 'Atribuir usuário a novos clientes e unidades',
    register_profile: 'Criar / editar perfis',
    register_anamnesis_template: 'Criar / editar template de anamnese',
    register_unit_type: 'Criar / editar tipos de unidade',
    register_council: 'Criar / editar conselho',
    register_specialty: 'Criar / editar especialidade',
    register_client: 'Criar / editar clientes',
    register_patient: 'Cadastrar / editar paciente',
    register_unit: 'Criar / editar unidades',
    register_user: 'Criar / editar usuários',

    list_all_users: 'Listar todos os usuários',
    list_clients: 'Acessar lista de clientes',
    list_units: 'Acessar lista de unidades',
    list_users: 'Acessar lista de usuários',
    list_patients: 'Acessar lista de pacientes',

    manager_create_agenda: 'Criar / editar agenda',
    manager_see_other_users_agenda: 'Acessar agenda de outros usuários *Solicitante',
    manager_transfer_agenda: 'Fazer transferência de agenda *Solicitante',
    manager_invite_patient: 'Convidar pacientes para o app',

    service_do_screening: 'Preencher detalhes do atendimento',
    service_follow_up: 'Acessar tela de Status / Acompanhamento de Atendimentos',
    service_create_to_other_users: 'Fazer agendamento para outros usuários',

    service_access_all_services: 'Acessar todos atendimentos',

    service_delete: 'Deletar atendimento',
    service_invite: 'Convidar para chamada',
    service_specialist: 'Assumir atendimento (Execultante)',
    service_requester: 'Realizar atendimento (Solicitante)',

    report_general: 'Acessar relatório geral',
    report_nps: 'Acessar relatório de profissionais e avaliação',
    report_production: 'Acessar relatório de unidades',
    report_service: 'Acessar relatório de atendimentos',
    report_patient: 'Acessar relatórios de paciente',
    report_agenda: 'Acessar relatório de agenda e faturamento',

    document_medical_certificate: 'Emitir atestado',
    document_prescription: 'Emitir receituário',
    document_medical_report: 'Emitir laudo',
    document_request_referral: 'Solicitar encaminhamento',
    document_request_test: 'Solicitar exame'
  }
}

export default profile_ptBR
