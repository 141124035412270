import ConsultationService from '../../../../services/consultation.service'
import Button from '../../../../components/button'
import { useEffect, useState } from 'react'
import { TCLE } from '../../../../types'
import { format } from 'date-fns'

export const TcleList = ({ id }: { id: string }) => {
  const [tcle, setTcle] = useState<TCLE[]>([])

  useEffect(() => {
    ConsultationService.listTCLE(id).then(setTcle)
  }, [id])

  const download = async (tcle_id: string) => {
    try {
      const response = await ConsultationService.downloadTCLE(tcle_id)

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'tcle.pdf')
      document.body.appendChild(link)
      link.click()

      // Limpar o link após o download
      link.parentNode?.removeChild(link)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div className='col-span-2 mb-3 flex flex-wrap items-center gap-4'>
      {tcle.map(item => (
        <div key={item.id} className='flex w-[300px] flex-col gap-2 rounded-md border-[1px] p-2'>
          <span className='text-xl font-semibold'>{item.patient?.name || item.user?.name}</span>
          <span className='opacity-70'>{format(item.created_at!, 'dd/MM/yyyy HH:mm')}</span>
          <Button label='Download' onClick={() => download(item.id!)} />
        </div>
      ))}
    </div>
  )
}
