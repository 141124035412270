import AnamnesisTemplateService from '../../services/anamnesis-template.service'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useToastContext } from '../../contexts/toast'
import { AnamnesisTemplate } from '../../types'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../routes/path'
import { useFormik } from 'formik'
import { v4 as uuid } from 'uuid'
import * as Yup from 'yup'

export const useAnamnesisTemplate = (id?: string) => {
  const { toast } = useToastContext()
  const navigate = useNavigate()

  const validationSchema = Yup.object({
    unit_id: Yup.string().required('Unit é obrigatório'),
    client_id: Yup.string().required('Client é obrigatório'),
    specialty_id: Yup.string().required('Specialty é obrigatório'),
    questions: Yup.array()
      .of(
        Yup.object().shape({
          required: Yup.boolean().optional(),
          options: Yup.array().of(Yup.string()).optional(),
          type: Yup.string().required('Tipo é obrigatório'),
          title: Yup.string().required('Título é obrigatório')
        })
      )
      .min(1, 'Pelo menos uma questão é obrigatória')
  })

  const initialValues = {
    unit_id: '',
    client_id: '',
    specialty_id: '',
    questions: []
  }

  const formik = useFormik<AnamnesisTemplate>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values: Partial<AnamnesisTemplate>) => {
      try {
        if (id && id !== 'new') {
          await AnamnesisTemplateService.update(id, {
            ...values,
            questions: values?.questions?.map(item => ({ ...item, id: item.id || uuid() })) || []
          })
          toast.success('Template de anamnese atualizado com sucesso')
        } else {
          await AnamnesisTemplateService.create({
            ...values,
            questions: values?.questions?.map(item => ({ ...item, id: item.id || uuid() })) || []
          })
          toast.success('Template de anamnese criado com sucesso')
        }

        navigate(ROUTES.anamnesis_template.list)
      } catch (error) {
        toast.error((error as Error).message || 'Algo deu errado')
      }
    }
  })

  return formik
}

export const useSubmitAnamnesis = () => {
  const { toast } = useToastContext()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (answers: { [key: string]: string | number }) => {
      console.log('Enviando as respostas:', answers)
      return Promise.resolve()
    },
    onSuccess: () => {
      toast.success('Respostas enviadas com sucesso')
      queryClient.invalidateQueries({ queryKey: ['anamnesisAnswers'] })
    },
    onError: (error: Error) => {
      toast.error(error.message || 'Algo deu errado')
    }
  })

  const formik = useFormik({
    initialValues: {},
    onSubmit: values => {
      mutation.mutate(values)
    },
    enableReinitialize: true
  })

  return formik
}
