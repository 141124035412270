/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react'
import BlankCard from '../../../../components/blank-card'
import AnyQuestion from './any-questions'
import {
  useGetAnamnesisByConsultation,
  useGetQuestionsDefault
} from '../../../../hooks/useAnamnesis'

const AnamnesisQuestionsDefault: React.FC<{
  id: string
  defaultValues: any
  readonly?: boolean
  setDefaultValues: (values: any) => void
}> = ({ id, defaultValues, readonly, setDefaultValues }) => {
  const { data: answers, isFetching: isLoadingAnswers } = useGetAnamnesisByConsultation(id)
  const { data: questions, isLoading: isLoadingQuestions } = useGetQuestionsDefault()

  useEffect(() => {
    setDefaultValues(answers?.default_answers || {})
  }, [answers?.default_answers, setDefaultValues])

  return (
    <>
      <span className='col-span-4 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4' />
      {isLoadingAnswers || isLoadingQuestions ? (
        <span>Carregando</span>
      ) : (
        <>
          {questions?.map((items, index) => (
            <BlankCard key={index} className='mb-4'>
              <span className='mb-10 block text-label-lg font-semibold'>{items.title}</span>
              <div className={items.className}>
                {items.questions.map(question => (
                  <AnyQuestion
                    key={question.id}
                    question={question}
                    values={defaultValues}
                    readonly={readonly}
                    handleValue={setDefaultValues}
                  />
                ))}
              </div>
            </BlankCard>
          ))}
        </>
      )}
    </>
  )
}

export default AnamnesisQuestionsDefault
