import Checkbox from '../../../../../components/checkbox'
import Radio from '../../../../../components/radio'
import { AnyQuestionProps } from '../any-questions'

export const CheckboxQuestion: React.FC<AnyQuestionProps> = ({
  handleValue,
  question,
  values,
  readonly
}) => {
  const currentValues = (values[question.id!] as string[]) || []

  const handleYesNoChange = (value: 'yes' | 'no' | undefined) => {
    handleValue({
      ...values,
      [`${question.id!}_yes_no`]: value
    })
  }

  const handleCheckboxChange = (option: string) => {
    if (currentValues.includes(option)) {
      handleValue({
        ...values,
        [question.id!]: currentValues.filter(value => value !== option)
      })
    } else {
      handleValue({
        ...values,
        [question.id!]: [...currentValues, option]
      })
    }
  }

  if (readonly) {
    if (currentValues.length) return <span>{currentValues}</span>
    return <span>-</span>
  }

  return (
    <div className='mt-2 flex flex-wrap items-center gap-2'>
      {question.yes_no && (
        <>
          <div className='mt-2 flex items-center gap-2 space-x-2'>
            <Radio
              size='md'
              state='enabled'
              selected={values[`${question.id!}_yes_no`] === 'yes'}
              onChange={() => handleYesNoChange('yes')}
            />
            Sim
          </div>
          <div className='mt-2 flex items-center gap-2 space-x-2'>
            <Radio
              size='md'
              state='enabled'
              selected={values[`${question.id!}_yes_no`] === 'no'}
              onChange={() => handleYesNoChange('no')}
            />
            Não
          </div>
        </>
      )}

      {values[`${question.id!}_yes_no`] === 'yes' && question.yes_no && (
        <div className='mx-1 mb-0 mt-auto h-6 w-[1px] bg-gray-400' />
      )}

      {(values[`${question.id!}_yes_no`] === 'yes' || !question.yes_no) &&
        question.options?.map((option, index) => (
          <div key={index} className='mt-2 flex items-center space-x-2'>
            <Checkbox
              isChecked={currentValues.includes(option)}
              onClick={() => handleCheckboxChange(option)}
            />
            <span className='mx-2 text-label-md font-bold'>{option}</span>
          </div>
        ))}
    </div>
  )
}
