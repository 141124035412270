import { useTranslation } from 'react-i18next'
import React, { useEffect, useRef, useState } from 'react'
import { ChatMessage } from './ChatMessage'
import { LogItem } from './LogItem'
import { useChatConnection } from '../../../../hooks/useChatConnection'
import { MessageInput } from './MessageInput'
import { ChatHeader } from './ChatHeader'
import { ConsultationMessage, MessageType } from '../../../../types'
import { TcleModal } from '../../../../components/connection/tcle-modal'
import { useAuth } from '../../../../contexts/auth'
import ConsultationService from '../../../../services/consultation.service'
import { useToastContext } from '../../../../contexts/toast'
import { Loading } from 'semente-js'

type Props = { id: string; onClose: VoidFunction }

export const ChatConnection: React.FC<Props> = ({ id, onClose }) => {
  const { t } = useTranslation('consultation_details')
  const [loadingTCLE, setLoadingTCLE] = useState(false)
  const { toast } = useToastContext()
  const { user } = useAuth()
  const {
    isLoading,
    message,
    messages,
    setMessage,
    showTcleModal,
    handleKeyPress,
    reloadMessages,
    handleSendMessage
  } = useChatConnection(id)

  const bottomRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // Sempre que as mensagens mudarem, o scroll será ajustado para o fim
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [messages])

  const acceptTcle = async () => {
    setLoadingTCLE(true)
    try {
      await ConsultationService.acceptTCLE(id)
      reloadMessages()
    } catch (err) {
      console.log(err)
      toast.error('Não foi possivel assinar o TCLE')
    }
    setLoadingTCLE(false)
  }

  return (
    <div className='mb-6 mr-6 mt-[104px] flex h-[calc(100dvh-128px)] flex-col gap-5 rounded-xl bg-white p-5'>
      {/* HEADER */}
      <ChatHeader />

      {/* MESSAGES */}
      <div className='flex-4 relative flex-1 overflow-y-auto p-4'>
        {isLoading && (
          <div className='absolute bottom-0 left-0 right-0  flex items-center justify-center'>
            <Loading />
          </div>
        )}
        <ChatMessages messages={messages} />
        <div ref={bottomRef} />
      </div>

      {/* INPUT */}
      <div className='sticky bottom-0 border-gray-200 bg-white '>
        <MessageInput
          message={message}
          setMessage={setMessage}
          handleKeyPress={handleKeyPress}
          buttonLabel={t('actions.sent')}
          handleSendMessage={handleSendMessage}
          placeholder={t('chat.textarea_placeholder')}
        />
      </div>

      <TcleModal
        person={{
          birthdate: user?.birthdate,
          cpf: user?.cpf,
          document: user?.document,
          name: user?.name
        }}
        acceptTcle={acceptTcle}
        isOpen={showTcleModal}
        rejectTcle={onClose}
        isLoading={loadingTCLE}
      />
    </div>
  )
}

export const ChatMessages: React.FC<{ messages: ConsultationMessage[] }> = ({ messages }) => {
  return (
    <>
      {messages.map(message => (
        <React.Fragment key={message.id}>
          {message.type === MessageType.LOG_ENTERED || message.type === MessageType.LOG_EXITED ? (
            <LogItem
              user={message.user?.name || ''}
              hour={message.created_at || ''}
              message={message.message || ''}
              type={message.type}
            />
          ) : (
            <ChatMessage
              userId={message.user?.id || ''}
              userName={message.user?.name || ''}
              image_id={message?.user?.image_id}
              message={message.message || ''}
              hour={message.created_at || ''}
            />
          )}
        </React.Fragment>
      ))}
    </>
  )
}
