import { Copy, PaperPlaneTilt } from '@phosphor-icons/react'
import Button from '../../../../../components/button'
import Modal from '../../../../../components/modal'
import { Consultation } from '../../../../../types'
import BlankCard from '../../../../../components/blank-card'
import Avatar from '../../../../../components/avatar'
import { useTranslation } from 'react-i18next'
import { useInvitePatient } from '../../../../../hooks/useConsultation'
import { useToastContext } from '../../../../../contexts/toast'

interface Props {
  isModalOpen: boolean
  closeModal: () => void
  consultation: Consultation
}

const InvitePatientModal = ({ isModalOpen, closeModal, consultation }: Props) => {
  const { t } = useTranslation('consultation_details')
  const { toast } = useToastContext()
  const consultationLink = `https://${window.location.hostname}/consultation/${consultation.care_type}/${consultation.id}/join`

  const { sendInvite, isLoading } = useInvitePatient(consultation?.id as string, closeModal)

  const handleCancel = () => {
    closeModal()
  }

  const handleInvite = () => {
    sendInvite()
  }

  const copyLinkToClipboard = () => {
    // Verifica se a API está disponível no navegador
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(consultationLink)
        .then(() => {
          toast.success('Link copiado com sucesso')
        })
        .catch(err => {
          console.log(err)
          toast.error(t('Falha ao copiar'))
        })
    } else {
      console.error('API de Clipboard não suportada pelo navegador.')
    }
  }

  return (
    <Modal title={t('invite_patient_modal.title')} isOpen={isModalOpen} onClose={handleCancel}>
      <div className='w-[380px] max-w-[90vw]'>
        <div className='mb-1'>
          <p className='text-body-md text-neutralContent-tertiary'>
            {t('invite_patient_modal.subtitle')}
          </p>
          <br />
        </div>
        <div className='my-4 flex justify-center'>
          <BlankCard className='flex w-full gap-3'>
            <div>
              <Avatar name={consultation?.patient!.name} />
            </div>
            <div className='flex flex-col'>
              <span className='text-label-lg text-primaryBrand-primary'>
                {consultation?.patient!.name}
              </span>
              <span className='text-body-sm text-neutralContent-tertiary'>
                {consultation?.patient!.cpf}
              </span>
            </div>
          </BlankCard>
        </div>
        <div className='flex w-full rounded-md border border-neutralContent-quartenary p-2'>
          <input readOnly className='w-full truncate' value={consultationLink} />
          <button type='button' onClick={copyLinkToClipboard} className='pl-2'>
            <Copy />
          </button>
        </div>
        <div className='mt-4 flex justify-end'>
          <Button
            type='button'
            label={t('actions.cancel')}
            size='sm'
            variant='tertiary'
            onClick={handleCancel}
          />
          <Button
            size='sm'
            label={t('actions.invite')}
            variant='primary'
            iconRight={<PaperPlaneTilt />}
            onClick={handleInvite}
            isLoading={isLoading}
          />
        </div>
      </div>
    </Modal>
  )
}

export default InvitePatientModal
