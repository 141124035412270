/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next'
import Modal from '../../../components/modal'
import Tab from '../../../components/tabs'
import { useCallback, useMemo } from 'react'

interface Props {
  open: boolean
  onClose: () => void
  response: any
}
export const ResponseModalInBatch: React.FC<Props> = ({ open, onClose, response }) => {
  const { t } = useTranslation('patient_list')

  const renderContent = useCallback(
    (type: 'registered' | 'invalid' | 'registered_and_invited') => {
      switch (type) {
        case 'invalid':
          return <CardStatus status='error' users={response?.invalid} />
        case 'registered_and_invited':
          return <CardStatus status='success' users={response?.registered_and_invited} />
        case 'registered':
          return <CardStatus status='info' users={response?.invited} />
      }
    },
    [response]
  )

  const tabs = useMemo(
    () => [
      {
        label: (
          <span className='text-label-sm'>
            ({response?.invalid}) {t('invite_modal_in_batch.response.tabs.invalid')}
          </span>
        ),
        content: renderContent('invalid'),
        disabled: false,
        activeColor: 'border-highlightRed-pure text-highlightRed-pure'
      },
      {
        label: (
          <span className='text-label-sm '>
            ({response?.registered_and_invited}){' '}
            {t('invite_modal_in_batch.response.tabs.registered_and_invited')}
          </span>
        ),
        content: renderContent('registered_and_invited'),
        disabled: false,
        activeColor: 'border-highlightGreen-pure text-highlightGreen-pure'
      },
      {
        label: (
          <span className='text-label-sm'>
            ({response?.invited}) {t('invite_modal_in_batch.response.tabs.registered')}
          </span>
        ),
        content: renderContent('registered'),
        disabled: false,
        activeColor: 'border-highlightBlue-pure text-highlightBlue-pure'
      }
    ],
    [renderContent, response, t]
  )

  return (
    <Modal className='w-1/2' isOpen={open} onClose={onClose}>
      <div>
        <div className='py-2 text-heading-xl'>{t('invite_modal_in_batch.response.title')}</div>
        <span className='text-body-md text-neutralContent-secondary'>
          {t('invite_modal_in_batch.response.description')}
        </span>

        <div>
          <Tab tabs={tabs} />
        </div>
      </div>
    </Modal>
  )
}

const CardStatus: React.FC<{
  status: 'info' | 'success' | 'error'
  users: number
}> = ({ status, users }) => {
  const { t } = useTranslation('patient_list')

  const bgColor =
    status === 'info'
      ? 'bg-highlightBlue-light'
      : status === 'success'
        ? 'bg-highlightGreen-light'
        : 'bg-highlightRed-light'

  const helperText =
    status === 'info'
      ? t('invite_modal_in_batch.response.info')
      : status === 'success'
        ? t('invite_modal_in_batch.response.success')
        : t('invite_modal_in_batch.response.errors')

  return (
    <div className={`rounded-md ${bgColor} rounded-md p-2 text-body-sm`}>
      <span className='font-bold'>
        {t('invite_modal_in_batch.response.user', { count: users })}
      </span>
      <span> {helperText}</span>
    </div>
  )
}
