import { SpinnerGap } from '@phosphor-icons/react'
import React from 'react'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string
  iconLeft?: JSX.Element
  iconRight?: JSX.Element
  isFloating?: boolean
  width?: number | string
  size?: 'lg' | 'md' | 'sm' | 'xs'
  state?: 'enable' | 'disable' | 'loading' | 'error'
  variant?: 'primary' | 'secondary' | 'tertiary' | 'neutral' | 'white'
  className?: string
  isLoading?: boolean
  onClick?: () => void
}

const getSizeLayout = (size: Props['size']) => {
  switch (size) {
    case 'lg':
      return 'px-5 py-4 '
    case 'sm':
      return 'px-3 py-2.5 '
    case 'xs':
      return 'px-2 py-[6px] '
    default:
      return 'px-4 py-3.5 '
  }
}

const getFloatingLayout = (isFloating: Props['isFloating']) => {
  if (isFloating) return 'shadow-md '
  return ''
}

const getVariantLayout = (variant: Props['variant'], state: Props['state']) => {
  switch (variant) {
    case 'secondary':
      if (state === 'disable')
        return 'bg-neutralBackground-disabled border-0 text-neutralContent-disabled'
      if (state === 'loading' || state === 'enable')
        return 'bg-neutralBackground-primary border border-solid border-primaryBrand-primary text-primaryBrand-primary hover:bg-primaryBrand-light transition-all'
      if (state === 'error')
        return 'bg-neutralBackground-primary border border-solid border-highlightRed-pure text-highlightRed-pure '
      return 'bg-neutral-background hover:bg-primaryBrand-light transition-all'
    case 'tertiary':
      if (state === 'disable') return 'bg-transparent text-neutralContent-disabled '
      if (state === 'loading') return 'bg-neutralBackground-primary text-neutralContent-primary '
      if (state === 'error') return 'bg-transparent text-highlightRed-pure '
      return 'bg-neutralBackground-primary text-primaryBrand-primary '
    case 'neutral':
      if (state === 'disable') return 'pointer-events: none '
      if (state === 'loading') return 'pointer-events: none '
      return 'bg-neutralBackground-disabled text-neutralContent-disabled '
    case 'white':
      if (state === 'disable') return 'pointer-events: none '
      if (state === 'loading') return 'pointer-events: none '
      return 'bg-neutralBackground-disabled text-black '
    default:
      if (state === 'disable')
        return 'bg-neutralBackground-disabled text-neutralContent-disabled border-0 '
      if (state === 'loading' || state === 'enable')
        return 'bg-primaryBrand-primary text-neutralContent-primaryInverse hover:bg-primaryBrand-dark transition-all'
      if (state === 'error') return 'bg-highlightRed-pure text-neutralContent-primaryInverse '
      return 'bg-primary-blue hover:bg-primaryBrand-dark transition-all'
  }
}

const getWidthLayout = (width: Props['width']) => {
  if (typeof width === 'number') return `w-[${width}px] `
  if (typeof width === 'string') {
    if (width === 'hug') return 'w-auto '
    if (width === 'full') return 'w-full '
    return `w-[${width}px] `
  }
  return 'w-auto '
}

const Button: React.FC<Props> = ({
  size = 'md',
  label,
  iconLeft,
  isLoading,
  iconRight,
  className,
  isFloating,
  state = 'enable',
  variant = 'primary',
  width = 'hug',
  onClick,
  ...rest
}) => {
  const sizeLayout = getSizeLayout(size)
  const floatingLayout = getFloatingLayout(isFloating)
  const variantLayout = getVariantLayout(variant, state)
  const widthLayout = getWidthLayout(width)

  return (
    <button
      disabled={state === 'disable'}
      onClick={isLoading ? undefined : onClick}
      {...rest}
      className={`${className} ${isLoading && 'relative'} flex items-center justify-center overflow-hidden rounded-500 ${widthLayout}${sizeLayout}${floatingLayout}${variantLayout} `}
    >
      {isLoading && (
        <div
          className={`absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center ${variantLayout}`}
        >
          <SpinnerGap size={20} className='animate-spin' />
        </div>
      )}
      {iconLeft && <span className={`${!!label && 'mr-2'}`}>{iconLeft}</span>}
      {label && <span className={`text-label-${size} mx-auto `}>{label} </span>}
      {iconRight && <span className='ml-2'>{iconRight}</span>}
    </button>
  )
}

export default Button
