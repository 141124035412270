import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from '../../../components/modal'
import Button from '../../../components/button'
import SelectUnitsModal, { ClientItem, UnitItem } from '../../../components/select-units-modal'
import Avatar from '../../../components/avatar'
import BlankCard from '../../../components/blank-card'
import { ArrowCircleDown, CheckCircle, FileCsv, SpinnerGap, X } from '@phosphor-icons/react'
import { FormikProps } from 'formik'

interface Props {
  formik: FormikProps<{
    patients: { cpf: string; cns_number: string; name: string; email: string }[]
    units: string[]
    clients: ClientItem[]
  }>
  loading: boolean
  open: boolean
  onClose: () => void
  setModalInvite: (value: { isOpen: boolean; type: 'individual' | 'batch' }) => void
}

export const InviteModalInBatch: React.FC<Props> = ({ formik, loading, open, onClose }) => {
  const { t } = useTranslation('patient_list')

  const [showUnitsModal, setShowUnitsModal] = useState(false)
  const [selectedClients, setSelectedClients] = useState<ClientItem[] | undefined | null>(undefined)

  const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const onRemoveUnit = (item: ClientItem, unitItem: UnitItem) => {
    setSelectedClients(prevSelected => {
      if (!prevSelected) return prevSelected

      const updatedClients = prevSelected.map(clientItem => {
        if (clientItem.client?.id === item.client?.id) {
          const updatedUnits = clientItem.units?.filter(u => u.unit?.id !== unitItem.unit?.id) || []
          return { ...clientItem, units: updatedUnits }
        }
        return clientItem
      })

      const updatedUnitIds = updatedClients.flatMap(clientItem =>
        (clientItem.units || []).map(u => u.unit?.id)
      )
      formik.setFieldValue('units', updatedUnitIds)
      formik.setFieldValue('clients', updatedClients)

      return updatedClients
    })
  }

  const handleFileSelect = () => {
    fileInputRef.current?.click()
  }

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      if (file.name.endsWith('.csv')) {
        // extrair os dados do arquivo csv
        const csvData = await file.text()
        const lines = csvData.split('\n')
        const patients = lines.slice(1).map(line => {
          const values = line.split(';')
          return {
            cpf: values[0],
            cns_number: values[1],
            name: values[2],
            email: values[3]
          }
        })

        formik.setFieldValue('patients', patients)
        setUploadedFile(file)
        setUploadSuccess(true)
      } else {
        alert(t('Selecione um arquivo CSV válido.'))
      }
    }
  }

  const handleRemoveFile = () => {
    setUploadedFile(null)
    setUploadSuccess(false)
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  const handleDownload = () => {
    const csvContent = `cpf;cns;nome;email`
    const blob = new Blob([csvContent], { type: 'text/csv' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = 'exemplo.csv'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  }

  const renderLoadingComponent = () => {
    if (loading) {
      return <LoadingState />
    }
  }

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      onBack={loading ? undefined : onClose}
      className='max-h-[65vh] w-full max-w-[95vw] overflow-y-scroll sm:w-[500px]'
    >
      {renderLoadingComponent()}
      {!loading && (
        <>
          <div className='my-2 text-heading-xs'>{t(`invite_modal_in_batch.title`)}</div>
          <p className='text-body-md text-neutralContent-quartenary'>
            {t(`invite_modal_in_batch.description`)}
          </p>
          <div className='mt-4'>
            {uploadedFile ? (
              <SelectedFile
                file={uploadedFile}
                success={uploadSuccess}
                onRemove={handleRemoveFile}
              />
            ) : (
              <>
                <div>
                  <Button
                    label={t('actions.download_plan')}
                    size='sm'
                    variant='secondary'
                    className='mt-4'
                    iconLeft={<ArrowCircleDown />}
                    onClick={handleDownload}
                  />
                </div>

                <div className='mt-4 flex flex-col items-center justify-center gap-2 rounded-2xl bg-neutralBackground-tertiary p-4 text-center'>
                  <FileCsv size={24} />
                  <span className='ml-2 text-heading-xs'>
                    {t('invite_modal_in_batch.upload_plan')}
                  </span>
                  <div className='w-[190px] text-body-sm text-neutralContent-secondary'>
                    {t('invite_modal_in_batch.send_plan_description')}
                  </div>

                  <div>
                    <Button
                      label={t('actions.select_file')}
                      variant='white'
                      className='text-black'
                      onClick={handleFileSelect}
                    />

                    {/* Input file oculto */}
                    <input
                      type='file'
                      accept='.csv'
                      ref={fileInputRef}
                      onChange={handleFileUpload}
                      style={{ display: 'none' }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          <div className='mt-4 flex flex-col gap-4'>
            <div>
              <div className='text-heading-xs'>
                {t('invite_modal_individual.fields.units.label')}
              </div>
              <div className='mb-4 text-body-md text-neutralContent-secondary'>
                {t('invite_modal_individual.fields.units.description')}
              </div>

              {selectedClients?.map(item => {
                if (item.client) {
                  return (
                    <div key={item.client.id}>
                      <div className='mb-4 flex items-center gap-2 text-label-md'>
                        <Avatar size='xs' imageID={item.client.image_id} name={item.client.name} />
                        {item.client.name}
                      </div>
                      <BlankCard>
                        {item.units?.map((unitItem, index) => (
                          <React.Fragment key={unitItem.unit?.id}>
                            <div className='flex items-center justify-between py-1'>
                              <div className='text-body-md text-neutralContent-secondary'>
                                {unitItem.unit?.name}
                              </div>
                              <X onClick={() => onRemoveUnit(item, unitItem)} />
                            </div>
                            {item.units && index !== item.units?.length - 1 && (
                              <div className='my-2 h-[1px] bg-neutralBorder-default' />
                            )}
                          </React.Fragment>
                        ))}
                      </BlankCard>
                    </div>
                  )
                }
                return null
              })}

              <Button
                label={t('actions.add_unit')}
                size='sm'
                variant='secondary'
                onClick={() => setShowUnitsModal(true)}
                className='mt-4'
              />
            </div>
          </div>

          <SelectUnitsModal
            multiple
            isOpen={showUnitsModal}
            clients={formik.values.clients || []}
            onClose={() => setShowUnitsModal(false)}
            onSelectMultiple={(client, units) => {
              formik.setFieldValue('clients', client)
              formik.setFieldValue('units', units)
              setSelectedClients(client as ClientItem[])
            }}
            isSearchable
          />

          <div className='mt-4 flex border-t pt-4'>
            <Button
              size='md'
              width={'full'}
              label={t('actions.proccess_data')}
              variant='primary'
              state={
                formik.values.units.length > 0 && !!formik.values.patients ? 'enable' : 'disable'
              }
              onClick={formik.handleSubmit}
            />
          </div>
        </>
      )}
    </Modal>
  )
}

export const SelectedFile: React.FC<{
  file: File
  success: boolean
  onRemove: () => void
}> = ({ file, success, onRemove }) => {
  return (
    <div className='flex items-center justify-between rounded-xl border border-neutralBorder-default px-4'>
      <div className='flex items-center gap-2'>
        <FileCsv
          size={24}
          className={success ? 'text-highlightGreen-pure' : 'text-neutralContent-secondary'}
        />
        <div>
          <span className='text-label-md'>{file.name}</span>
          {success && (
            <div className='flex items-center'>
              <CheckCircle className='text-highlightGreen-pure' />
              <span className='text-body-md text-highlightGreen-pure'>Upload Concluído</span>
            </div>
          )}
        </div>
      </div>
      <X className='text-neutralContent-secondary' onClick={onRemove} />
    </div>
  )
}

const LoadingState = () => {
  const { t } = useTranslation('patient_list')

  return (
    <div className='flex flex-col items-center gap-6 rounded-2xl bg-primaryBrand-light p-4 '>
      <SpinnerGap className='animate-spin text-neutralBorder-brand' size={26} />
      <div className='text-heading-xs'>{t('invite_modal_in_batch.processing_data')}</div>
      <span className='text-body-sm text-neutralContent-secondary'>
        {t('invite_modal_in_batch.wait_a_moment')}
      </span>
    </div>
  )
}
