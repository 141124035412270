/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import Select from 'react-select'
import { generateHourOptions } from '../../helpers/generateHourOptions'

type HourSelectProps = {
  value: string
  label?: string
  min?: string
  max?: string
  onChange: (value: string) => void
  padding?: number | string
}

const HourSelect: React.FC<HourSelectProps> = ({ value, label, min, max, onChange, padding }) => {
  const options = generateHourOptions(min, max)

  const customStyles = {
    control: (base: any) => ({
      ...base,
      backgroundColor: 'transparent',
      borderColor: '#E5E7EB',
      borderRadius: '8px',
      padding: padding || '0.75rem',
      fontSize: '0.875rem',
      fontWeight: '500',
      color: '#4B5563',
      display: 'flex',
      alignItems: 'center',
      ':hover': {
        borderColor: '#DADEE7'
      },
      boxShadow: 'none'
    }),
    singleValue: (base: any) => ({
      ...base,
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      color: '#1F2937'
    }),
    menu: (base: any) => ({
      ...base,
      borderRadius: '0.375rem',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
    })
  }

  const SingleValue = (data: any) => {
    return (
      <div className='mt-[-18px] w-full'>
        {!!label && (
          <span className='mr-2 text-label-md text-neutralContent-tertiary'>{label}</span>
        )}

        <span className='text-body-md text-neutralContent-quartenary'>{data.data.label}</span>
      </div>
    )
  }

  return (
    <div className='flex flex-col'>
      <Select
        options={options}
        isSearchable={false}
        styles={customStyles}
        components={{ SingleValue }}
        classNamePrefix='hour-select'
        placeholder={'Selecione a hora'}
        value={options.find(option => option.value === value)}
        onChange={selected => onChange(selected?.value || '')}
      />
    </div>
  )
}

export default HourSelect
