/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { ConfirmationExitCallModal, FinishConsultationModal } from './DialogsConfirmationCall'
import { useGetConsultation, useStartConsultation } from '../../../hooks/useConsultation'
import { ConsultationDetailsSkeleton } from './skeleton/ConsultationDetailsSkeleton'
import { CARE_TYPE, Consultation, CONSULTATION_STATUS } from '../../../types'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { TclePerson } from '../../../components/connection/tcle-modal'
import { Check, DoorOpen, PencilSimple } from '@phosphor-icons/react'
import ProtectedComponent from '../../../components/protected'
import { useToastContext } from '../../../contexts/toast'
import Connection from '../../../components/connection'
import ConsultationSummary from './ConsultationSummary'
import { ChatConnection } from '../chat/ChatConnection'
import PermissionEnum from '../../../types/permissions'
import Content from '../../../components/content'
import { useAuth } from '../../../contexts/auth'
import Button from '../../../components/button'
import { useTranslation } from 'react-i18next'
import { ROUTES } from '../../../routes/path'
import PatientDetails from './PatientDetails'
import CameraPreview from './CameraPreview'
import ConsultationTabs from './tabs'
import { NPSModal } from './NPSModal'

const ConsultationDetailsPage: React.FC = () => {
  const { t } = useTranslation('consultation_details')
  const { id, care_type } = useParams<{ id: string; care_type: CARE_TYPE }>()
  const navigate = useNavigate()
  const { user } = useAuth()

  const { toast } = useToastContext()
  const [isStarted, setIsStarted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [openNpsModal, setOpenNpsModal] = useState(false)
  const [openCameraPreview, setCameraPreview] = useState(false)
  const [consultation, setConsultation] = useState<Consultation>()
  const [openConfirmationExitModal, setOpenConfirmationExitModal] = useState(false)
  const [openConfirmationFinishModal, setOpenConfirmationFinishModal] = useState(false)

  const { data, refetch } = useGetConsultation(id)
  const { mutate: startConsultation } = useStartConsultation()
  const [searchParams] = useSearchParams()
  const patientID = searchParams.get('patient')

  useEffect(() => {
    setConsultation(data)
  }, [data])

  const handleBack = () => {
    if (openCameraPreview) setCameraPreview(false)
    else if (!!patientID) {
      return navigate(ROUTES.patient.details.replace(':id', patientID))
    } else {
      return navigate(ROUTES.consultation.list.replace(':care_type', care_type!))
    }
  }

  const handleEdit = () => {
    navigate(ROUTES.consultation.edit.replace(':id', id!).replace(':care_type', care_type!))
  }

  const handleFinishConnection = async () => {
    setConsultation(undefined)
    setIsStarted(false)

    const response = await refetch()
    setOpenNpsModal(response.data?.status === CONSULTATION_STATUS.FINISHED)
  }

  const handleStartConsultation = () => {
    if (consultation?.status === CONSULTATION_STATUS.FINISHED) return

    if (
      consultation?.status === CONSULTATION_STATUS.SCHEDULED ||
      consultation?.status === CONSULTATION_STATUS.WAITING ||
      consultation?.status === CONSULTATION_STATUS.IN_ROOM
    ) {
      setIsLoading(true)

      startConsultation(id!, {
        onSuccess: data => {
          if (data.care_type !== CARE_TYPE.CHAT) {
            setCameraPreview(true)
          } else {
            setIsStarted(true)
          }

          setIsLoading(false)
          setConsultation(data)
        },
        onError: () => {
          setIsLoading(false)

          toast.error('Não foi possível iniciar a chamada')
        }
      })
    }
  }

  const handleStartCall = () => {
    setIsStarted(true)
    setCameraPreview(false)
  }

  const handleExitCall = () => {
    setCameraPreview(false)
    setIsStarted(false)
  }

  const handleFinishCall = async () => {
    setOpenNpsModal(true)
    const response = await refetch()
    setOpenNpsModal(response.data?.status === CONSULTATION_STATUS.FINISHED)
    handleExitCall()
  }

  useEffect(() => {
    if (
      consultation?.status === CONSULTATION_STATUS.FINISHED &&
      !!consultation.patient?.cpf &&
      consultation.patient?.cpf === user?.cpf
    ) {
      toast.error('A consulta já foi finalizada! Você não pode mais realizar ações nela.')
      navigate(ROUTES.consultation.list.replace(':care_type', consultation.care_type!))
    }
  }, [consultation, user])

  const leftContent = () => {
    return (
      <div className='flex items-center gap-4'>
        <Button
          size='sm'
          variant='primary'
          label={t('actions.exit_call')}
          iconLeft={<DoorOpen size={16} />}
          onClick={() => setOpenConfirmationExitModal(true)}
        />
        <Button
          size='sm'
          state='error'
          variant='primary'
          iconLeft={<Check size={16} />}
          label={t('actions.finish_call')}
          onClick={() => setOpenConfirmationFinishModal(true)}
        />
      </div>
    )
  }

  const rightContent = () => {
    return (
      <div className='flex items-center gap-2'>
        {(consultation?.status !== CONSULTATION_STATUS.FINISHED || !consultation.patient_id) && (
          <ProtectedComponent
            permissions={[
              PermissionEnum.service_requester,
              PermissionEnum.service_create_to_other_users
            ]}
          >
            <Button
              size='sm'
              variant='secondary'
              onClick={handleEdit}
              label={t('actions.edit')}
              iconLeft={<PencilSimple size={16} />}
            />
          </ProtectedComponent>
        )}

        {consultation?.status === CONSULTATION_STATUS.IN_ROOM &&
          !isStarted &&
          !openCameraPreview && (
            <ProtectedComponent
              permissions={[
                PermissionEnum.service_requester,
                PermissionEnum.service_specialist,
                PermissionEnum.service_create_to_other_users
              ]}
            >
              <Button
                size='sm'
                iconLeft={<Check size={16} />}
                label={t('actions.complete_consultation')}
                onClick={() => setOpenConfirmationFinishModal(true)}
              />
            </ProtectedComponent>
          )}
      </div>
    )
  }

  return (
    <>
      <Content
        rightContent={rightContent()}
        leftContent={isStarted && leftContent()}
        className={isStarted ? 'mr-[400px]' : ''}
        onBack={!isStarted ? handleBack : undefined}
      >
        <div className='mx-[-24px] mt-9 flex max-h-[calc(100vh-230px)] flex-col gap-9 overflow-y-scroll px-6 sm:col-span-2'>
          {isLoading || !consultation ? (
            <ConsultationDetailsSkeleton />
          ) : (
            <>
              <PatientDetails
                onStart={handleStartConsultation}
                consultation={consultation}
                isStarted={isStarted}
              />

              <ConsultationSummary consultation={consultation} />

              {id && (
                <ConsultationTabs
                  id={id}
                  care_type={care_type}
                  cpf={consultation.patient?.cpf as string}
                  specialist_id={consultation.specialist_id}
                  patient_id={consultation.patient?.id as string}
                  is_finished={consultation.status === CONSULTATION_STATUS.FINISHED}
                />
              )}
            </>
          )}
        </div>

        {openCameraPreview && <CameraPreview onStart={handleStartCall} />}
      </Content>

      <FinishConsultationModal
        id={id as string}
        onFinish={handleFinishCall}
        setIsLoading={setIsLoading}
        open={openConfirmationFinishModal}
        onClose={() => setOpenConfirmationFinishModal(false)}
      />

      <ConfirmationExitCallModal
        onExit={handleExitCall}
        open={openConfirmationExitModal}
        onClose={() => setOpenConfirmationExitModal(false)}
      />

      <NPSModal open={openNpsModal} onClose={() => setOpenNpsModal(false)} />

      {(care_type === CARE_TYPE.ELECTIVE_CARE || care_type === CARE_TYPE.EMERGENCY_CARE) &&
        isStarted &&
        id && (
          <div className='absolute bottom-[16px] right-0 top-[-16px] flex w-[400px] flex-col'>
            <Connection id={id} onFinish={handleFinishConnection} person={user as TclePerson} />
          </div>
        )}

      {care_type === CARE_TYPE.CHAT && isStarted && id && (
        <div className='absolute bottom-0 right-0 top-0 flex w-[400px] flex-col'>
          <ChatConnection id={id} onClose={() => setIsStarted(false)} />
        </div>
      )}
    </>
  )
}

export default ConsultationDetailsPage
